import React, { useCallback, useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import Tabs from "@Molecule/Tabs";
import SearchBar from "@Atom/SearchBar";
import Table from "@Molecule/Table";
import useWindowSize from "@Hooks/useWindowSize";
import TabHeader from "@Atom/TabHeader";
import Icon from "@Atom/Icon";
import DropdownMoreCircle from "@Atom/DropdownMoreCircle";
import { useLocation, useNavigate } from "react-router-dom";
import ProfileEmployee from "@Atom/ProfileEmployee";
import moment from "moment";
import Toast from "@Atom/Toast";
import CustomEmptyHandlerPage from "@Atom/CustomEmptyHandlerPage";
import Images from "@Theme/Images";
import ModalAddMembers from "@Molecule/_modal/AddMembers";
import AvatarImage from "@Assets/Images/ava-default.png";
import { useDebounce } from "@Hooks/useDebounce";
import useQuery from "@Hooks/useQuery";
import Spinner from "@Atom/Spinner";
import { capitalizeEachWord } from "helpers/capitalizeEachWord";
import { deleteMembersOnLocation } from "@Services/members/deleteMembersOnLocation";
import { deleteSquad } from "@Services/squad/deleteSquad";
export default function ListMembersPage({ data, dataSquad, isEmpty = false }) {
  const isAdmin = localStorage.getItem("role") === "ADMIN";
  moment.locale("id");
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const query = useQuery();
  const nameLocation = sessionStorage.getItem("nameDetailInfoWorkLocation");
  const { pathname } = useLocation();

  const [loading, setLoading] = useState(false);

  const [tabsActive, setTabsActive] = useState(
    +sessionStorage.getItem("tabsActive") || 0
  );
  useEffect(() => {
    sessionStorage.setItem("tabsActive", tabsActive);
  }, [tabsActive]);

  const listTabs = ["Daftar Personil", "Daftar Regu"];

  const limitQuery = query.get("limit") || 10;
  const pageQuery = query.get("page") || 1;
  // const searchQuery = query.get("search") || "";

  const [limitPersonil, setLimitPersonil] = useState(+limitQuery);
  const [pagePersonil, setPagePersonil] = useState(+pageQuery);

  const [limitSquad, setLimitSquad] = useState(+limitQuery);
  const [pageSquad, setPageSquad] = useState(+pageQuery);

  const [searchQueryPersonil, setSearchQueryPersonil] = useState("");
  const [searchQuerySquad, setSearchQuerySquad] = useState("");
  const debouncedPersonil = useDebounce(searchQueryPersonil, 500);
  const debouncedSquad = useDebounce(searchQuerySquad, 500);

  const [selectedName, setSelectedName] = useState("");
  const [dataSelectedForSetUp, setdataSelectedForSetUp] = useState({
    name: "",
    role: "",
    profilePicture: "",
  });
  useEffect(() => {
    setLimitPersonil(+limitQuery);
    setLimitSquad(+limitQuery);
    setPagePersonil(+pageQuery);
    setPageSquad(+pageQuery);
  }, [limitQuery, pageQuery]);

  useEffect(() => {
    const prevPagePersonil = Math.min(
      data?.totalPage ? data.totalPage : 1,
      pagePersonil
    );
    const prevPageSquad = Math.min(
      data?.totalPage ? data.totalPage : 1,
      pageSquad
    );
    if (tabsActive === 0) {
      navigate(
        `${pathname}?q=q&page=${prevPagePersonil}&limit=${limitPersonil}&search=${debouncedPersonil}`
      );
    } else {
      navigate(
        `${pathname}?q=q&page=${prevPageSquad}&limit=${limitSquad}&search=${debouncedSquad}`
      );
    }
  }, [
    data?.totalPage,
    debouncedPersonil,
    debouncedSquad,
    limitPersonil,
    limitSquad,
    navigate,
    pagePersonil,
    pageSquad,
    pathname,
    tabsActive,
  ]);

  useEffect(() => {
    if (+pageQuery !== pagePersonil) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [debouncedPersonil, pagePersonil, pageQuery]);

  const [openChoose, setOopenChoose] = useState();

  const [sort, setSort] = useState({
    column: "name",
    type: "ASC" || "DESC",
  });

  const sortedDataListPersonil = useMemo(() => {
    const sorted = [...data?.data]?.sort((a, b) => {
      if (sort.column) {
        if (sort.type === "ASC") {
          return a?.name?.localeCompare(b?.name);
        } else {
          return b?.name?.localeCompare(a?.name);
        }
      }
      return 0;
    });

    return sorted;
  }, [data?.data, sort.column, sort.type]);

  const [isOpenSetUpSquad, setIsOpenSetUpSquad] = useState(false);

  const [isLoadingDeleteMembers, setIsLoadingDeleteMembers] = useState(false);
  const [isSuccessDeleteMembers, setIsSuccessDeleteMembers] = useState(false);
  const [isErrorDeleteMembers, setIsErrorngDeleteMembers] = useState(false);

  const handleDeleteMemberOnLocation = useCallback(
    async (id) => {
      setIsSuccessDeleteMembers(false);
      setIsErrorngDeleteMembers(false);
      setIsLoadingDeleteMembers(true);
      try {
        const res = await deleteMembersOnLocation(id);
        if (res.status) {
          setIsSuccessDeleteMembers(true);
          navigate(pathname, { replace: true });
        }
      } catch (error) {
        console.log("Error delete member on location: ", error);
        setIsErrorngDeleteMembers(true);
      } finally {
        setIsLoadingDeleteMembers(false);
      }
    },
    [navigate, pathname]
  );

  const [isSuccessDeleteSquad, setIsSuccessDeleteSquad] = useState(false);
  const [isErrorDeleteSquad, setIsErrorDeleteSquad] = useState(false);

  const handleDeleteSquad = useCallback(
    async (id) => {
      setIsSuccessDeleteSquad(false);
      setIsErrorDeleteSquad(false);
      setIsLoadingDeleteMembers(true);
      try {
        const res = await deleteSquad(id);
        if (res.status === 200) {
          setIsSuccessDeleteSquad(true);
          navigate(pathname, { replace: true });
        }
      } catch (error) {
        console.log("Error delete regu: ", error);
        setIsErrorDeleteSquad(true);
      } finally {
        setIsLoadingDeleteMembers(false);
      }
    },
    [navigate, pathname]
  );

  const templateDataPersonil = useMemo(() => {
    return {
      data:
        sortedDataListPersonil?.map((m) => ({
          id: m?._id || "-",
          employeeID: m?.employeeID || "-",
          name: capitalizeEachWord(m?.name) || "-",
          profilePicture: m?.profilePicture || AvatarImage,
          level: capitalizeEachWord(m?.role) || "-",
          assignment: capitalizeEachWord(m?.assignAs) || "-",
          squad: capitalizeEachWord(m?.group?.name) || "-",
        })) || [],
      totalData: data?.totalData || "-",
      columns: [
        {
          name: "name",
          width: width > 851 ? "max-content" : "200px",
          title: (
            <TabHeader
              text="Nama"
              column={"name"}
              sort={sort}
              setSort={setSort}
              isSorted
            />
          ),
          renderData: (row) => (
            <ProfileEmployee
              name={row?.name}
              idEmployee={row.employeeID}
              image={row?.profilePicture}
            />
          ),
        },

        {
          name: "level",
          rowExpanded: false,
          width: width > 851 ? "max-content" : "200px",
          clickExpand: false,
          title: <TabHeader text="Jabatan" column={"level"} />,
          renderData: (row) => <span>{row?.level}</span>,
          hideOnMobile: true,
        },

        {
          name: "assignment",
          width: width > 851 ? "max-content" : "200px",
          title: <TabHeader text="Penugasan" />,
          hideOnMobile: true,
          renderData: (row) => <span>{row?.assignment}</span>,
        },

        {
          name: "squad",
          width: width > 851 ? "max-content" : "200px",
          textAlign: "end",
          title: <TabHeader text="Regu" column={"contractType"} />,
          hideOnMobile: true,
          renderData: (row) => <span>{row?.squad}</span>,
        },
        {
          name: "",
          renderData: (row) => (
            <div
              style={{
                display: isAdmin ? "flex" : "none",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <DropdownMoreCircle
                id={row?.id}
                openChoose={openChoose}
                setOopenChoose={setOopenChoose}
                tabs={tabsActive}
                isAlreadySquad={row?.squad === "-" ? false : row?.squad}
                onClickSquad={() => {
                  setIsOpenSetUpSquad(true);
                  setdataSelectedForSetUp(row);
                }}
                onClickDelete={
                  isLoadingDeleteMembers
                    ? () => void {}
                    : () => {
                        handleDeleteMemberOnLocation(row?.id);
                        setSelectedName(row?.name);
                      }
                }
                tabsActive={tabsActive}
              ></DropdownMoreCircle>
            </div>
          ),
        },
      ],
    };
  }, [
    sortedDataListPersonil,
    data?.totalData,
    width,
    sort,
    openChoose,
    tabsActive,
    isLoadingDeleteMembers,
    handleDeleteMemberOnLocation,
    isAdmin,
  ]);

  const sortedDataListSquad = useMemo(() => {
    const sorted = [...dataSquad?.data]?.sort((a, b) => {
      if (sort.column) {
        if (sort.type === "ASC") {
          return a?.name?.localeCompare(b?.name);
        } else {
          return b?.name?.localeCompare(a?.name);
        }
      }
      return 0;
    });

    return sorted;
  }, [dataSquad?.data, sort.column, sort.type]);

  const templateListSquad = useMemo(() => {
    return {
      data:
        sortedDataListSquad?.map((ds) => ({
          id: ds?._id || "-",
          squadName: capitalizeEachWord(ds?.groupName) || "-",
          totalPersonils: ds?.memberCount || "-",
          idEmployee: ds.latestDanru?.employeeId || "-",
          name: capitalizeEachWord(ds.latestDanru?.name) || "-",
          profilePicture: ds.latestDanru?.profilePicture || AvatarImage,
        })) || [],
      totalData: dataSquad.totalData || 0,
      columns: [
        {
          name: "squadName",
          width: width > 851 ? "max-content" : "200px",
          title: (
            <TabHeader
              text="Nama Regu"
              column={"squadName"}
              sort={sort}
              setSort={setSort}
              isSorted
            />
          ),
          renderData: (row) => <span>{row?.squadName}</span>,
        },

        {
          name: "totalPersonils",
          width: width > 851 ? "max-content" : "200px",
          title: <TabHeader text="Jumlah Anggota" />,
          hideOnMobile: true,
          renderData: (row) => <span>{row?.totalPersonils}</span>,
        },

        {
          name: "",
          width: width > 851 ? "max-content" : "200px",
          textAlign: "end",
          title: <TabHeader text="Danru" />,
          renderData: (row) => (
            <ProfileEmployee
              idEmployee={row.idEmployee}
              image={row.profilePicture}
              name={row.name}
            />
          ),
          hideOnMobile: true,
        },

        {
          name: "",
          renderData: (row) => (
            <div
              style={{
                display: isAdmin ? "flex" : "none",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <DropdownMoreCircle
                id={row?.id}
                openChoose={openChoose}
                setOopenChoose={setOopenChoose}
                tabs={tabsActive}
                onClickSquad={() => {
                  setIsOpenSetUpSquad(true);
                  setdataSelectedForSetUp(row);
                }}
                tabsActive={tabsActive}
                onClickDeleteSquad={
                  isLoadingDeleteMembers
                    ? () => void {}
                    : () => {
                        handleDeleteSquad(row.id);
                        setSelectedName(row?.squadName);
                      }
                }
              ></DropdownMoreCircle>
            </div>
          ),
        },
      ],
    };
  }, [
    sortedDataListSquad,
    dataSquad.totalData,
    width,
    sort,
    openChoose,
    tabsActive,
    isLoadingDeleteMembers,
    handleDeleteSquad,
    isAdmin,
  ]);

  const [isOpenModalPersonil, setIsOpenModalPersonil] = useState(false);
  const [isTransition, setIsTransition] = useState(false);
  const handleOpenModalUpload = () => {
    setIsOpenModalPersonil(!isOpenModalPersonil);
  };

  useEffect(() => {
    if (isOpenSetUpSquad || isOpenModalPersonil) {
      setTimeout(() => {
        setIsTransition(true);
      }, 100);
    }
  }, [isOpenModalPersonil, isOpenSetUpSquad]);

  return (
    <>
      <Toast
        isopen={isErrorDeleteSquad}
        color="red"
        text={` Regu ${selectedName} gagal di hapus`}
        onClose={() => setIsErrorDeleteSquad(false)}
      />

      <Toast
        isopen={isSuccessDeleteSquad}
        color="green"
        text={`Regu ${selectedName} Berhasil di hapus dari lokasi ${sessionStorage.getItem(
          "nameDetailInfoWorkLocation"
        )}`}
        onClose={() => setIsSuccessDeleteSquad(false)}
      />

      <Toast
        isopen={isErrorDeleteMembers}
        color="red"
        text={`Gagal menghapus ${selectedName} dari lokasi ${sessionStorage.getItem(
          "nameDetailInfoWorkLocation"
        )}`}
        onClose={() => setIsErrorngDeleteMembers(false)}
        timeOut={5000}
      />

      <Toast
        isopen={isSuccessDeleteMembers}
        color="green"
        text={`Berhasil menghapus ${selectedName} dari lokasi ${sessionStorage.getItem(
          "nameDetailInfoWorkLocation"
        )}`}
        onClose={() => setIsSuccessDeleteMembers(false)}
      />

      {(isOpenSetUpSquad || isOpenModalPersonil) && ( // & REGU
        <ModalAddMembers
          isOpen={isOpenSetUpSquad || isOpenModalPersonil}
          onClose={() => {
            setIsOpenModalPersonil(false);
            setIsTransition(false);
            setIsOpenSetUpSquad(false);
          }}
          isTransition={isTransition}
          tabsActive={tabsActive}
          isFromEllipsisMenu={isOpenSetUpSquad}
          templateListSquad={dataSquad}
          dataSelectedForSetUp={dataSelectedForSetUp}
        />
      )}

      {isEmpty ? (
        <CustomEmptyHandlerPage
          title="Belum Ada Daftar Personil & Regu Ditambahkan"
          description="Silakan lakukan penambahan personil untuk ditugaskan di lokasi ini"
          btnText="Tambah Personil"
          onClick={handleOpenModalUpload}
          images={Images.EMPTY_PERSONAL_GUARD}
          isAddButton={isAdmin}
        />
      ) : (
        <>
          <section className={Styles.containerListEmployee}>
            <div className={Styles.wrapperListEmployee}>
              <div className={Styles.headerList}>
                <div className={Styles.header}>
                  <Icon
                    icon={"arrow-chevron-left"}
                    color={"#3E4856"}
                    size={"20"}
                    className={Styles.backIcon}
                    onClick={() => navigate("/work-location")}
                  />
                  <h2 className={Styles.headerText}>{nameLocation}</h2>
                </div>
                <Tabs
                  setIsActive={setTabsActive}
                  isActive={tabsActive}
                  listTabs={listTabs}
                  style={{ marginBottom: "12px" }}
                />
                <div className={Styles.fieldTable}>
                  <div className={Styles.filtering}>
                    <div style={{ width: "295px" }}>
                      <SearchBar
                        setValue={
                          tabsActive === 0
                            ? setSearchQueryPersonil
                            : setSearchQuerySquad
                        }
                        value={
                          tabsActive === 0
                            ? searchQueryPersonil
                            : searchQuerySquad
                        }
                        size="m"
                      />
                    </div>
                  </div>
                  {width > 851 && isAdmin && (
                    <div
                      className={Styles.btnAdd}
                      onClick={() => setIsOpenModalPersonil(true)}
                      style={{ width: "180px" }}
                    >
                      <Icon
                        icon={"add-circle"}
                        color={"#2C5364"}
                        size={"16"}
                        className={Styles.iconAdd}
                      />
                      <span>
                        Tambah {tabsActive === 0 ? "Personil" : "Regu"}
                      </span>
                    </div>
                  )}
                </div>
                <div style={{ position: "relative" }}>
                  {(loading ||
                    ((tabsActive === 0
                      ? searchQueryPersonil.length > 1
                      : searchQuerySquad.length > 1) &&
                      (debouncedPersonil || debouncedSquad) !==
                        query.get("search"))) && (
                    <div
                      style={{
                        position: "absolute",
                        top: "40%",
                        left: "50%",
                        zIndex: "9999",
                      }}
                    >
                      <Spinner width="50px" />
                    </div>
                  )}

                  {isLoadingDeleteMembers && (
                    <div
                      style={{
                        position: "absolute",
                        top: "40%",
                        left: "50%",
                        zIndex: "9999",
                      }}
                    >
                      <Spinner width="50px" />
                    </div>
                  )}

                  <Table
                    className={Styles.table}
                    data={
                      tabsActive === 0
                        ? templateDataPersonil.data
                        : templateListSquad.data
                    }
                    columns={
                      tabsActive === 0
                        ? templateDataPersonil.columns
                        : templateListSquad.columns
                    }
                    page={tabsActive === 0 ? pagePersonil : pageSquad}
                    limit={tabsActive === 0 ? limitPersonil : limitSquad}
                    totalData={
                      tabsActive === 0
                        ? templateDataPersonil.totalData
                        : templateListSquad.totalData
                    }
                    setLimit={
                      tabsActive === 0 ? setLimitPersonil : setLimitSquad
                    }
                    setPage={tabsActive === 0 ? setPagePersonil : setPageSquad}
                    totalPage={
                      tabsActive === 0
                        ? Math.ceil(
                            templateDataPersonil.totalData / limitPersonil
                          )
                        : Math.ceil(templateListSquad.totalData / limitSquad)
                    }
                    withPagination
                    withNumbering
                    widthNumber="20px"
                    heightEmpty="47px"
                    isCustomEmptyHandler
                    customEmptyHandler={() =>
                      tabsActive === 0 ? (
                        <div
                          style={{
                            height: "100%",
                            padding: "40px",
                          }}
                        >
                          <CustomEmptyHandlerPage
                            images={Images.EMPTY_PERSONAL_GUARD}
                            title={
                              debouncedPersonil
                                ? `Data Tidak Ditemukan`
                                : "Belum Ada Daftar Personil"
                            }
                            description={
                              debouncedPersonil
                                ? ` ${query.get(
                                    "search"
                                  )} belum terdaftar dilokasi, Cari kata kunci lain`
                                : `Silahkan lakukan penambahan personil untuk ditugaskan di lokasi ini`
                            }
                            btnText="Tambah Personil"
                            onClick={() => setIsOpenModalPersonil(true)}
                            isAddButton={isAdmin}
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            height: "100%",
                            padding: "40px",
                          }}
                        >
                          <CustomEmptyHandlerPage
                            images={Images.EMPTY_PERSONAL_GUARD}
                            title={
                              debouncedSquad
                                ? `Data Tidak Ditemukan `
                                : "Tidak Ada Data Regu"
                            }
                            description={
                              debouncedSquad
                                ? ` ${query.get(
                                    "search"
                                  )} belum terdaftar dilokasi, Cari kata kunci lain`
                                : "Silahkan lakukan penambahan data regu di lokasi ini"
                            }
                            btnText="Tambah Regu"
                            onClick={() => setIsOpenModalPersonil(true)}
                            isAddButton={isAdmin}
                          />
                        </div>
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
}
