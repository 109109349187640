import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Styles from "./style.module.scss";
import CustomEmptyHandlerPage from "@Atom/CustomEmptyHandlerPage";
import ImagesEmptyAnnouncements from "@Assets/Images/empty-images-announcements.png";
import SearchBar from "@Atom/SearchBar";
import Button from "@Atom/Button";
import Icon from "@Atom/Icon";
import moment from "moment";
import InputField from "@Atom/InputField";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import TextEditor from "@Atom/TextEditor";
import { getAllAnnouncements } from "@Services/announcements/getAllAnnouncements";
import { fileBaseUrl } from "configs";
import { useDebounce } from "@Hooks/useDebounce";
import { createAnnouncement } from "@Services/announcements/createAnnouncement";
import Toast from "@Atom/Toast";
import Skeleton, { BoxSkeleton } from "@Atom/Skeleton";
import { upateAnnouncement } from "@Services/announcements/updateAnnouncement";
import { deleteAnnouncement } from "@Services/announcements/deleteAnnouncement";
import { viewUpateAnnouncement } from "@Services/announcements/viewUpdateAnnouncement";
import useWindowSize from "@Hooks/useWindowSize";
import Spinner from "@Atom/Spinner";
import { SideBarStates } from "contexts/SideBarStates";
export default function Announcements({ isLoader = true }) {
  const isAdmin = localStorage.getItem("role") === "ADMIN";
  const savedInputAnnouncements = "savedInputAnnouncements";
  const savedOpenDetail = "openDetailAnnouncement";
  const [data, setData] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const debounceQuery = useDebounce(searchQuery, 500);
  const { height } = useWindowSize();
  const [isLoadingGet, setIsLoadingGet] = useState(isLoader);
  const [isLoadingHit, setIsLoadingHit] = useState(false);
  const [isLoadingScroll, setIsLoadingScroll] = useState(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [openDetail, setOpenDetail] = useState(
    sessionStorage.getItem(savedOpenDetail) || ""
  );
  const [isSuccessCreate, setIsSuccessCreate] = useState(false);
  const [isErrorCreate, setIsErrorCreate] = useState(false);
  const [isSuccessUpdate, setIsSuccessUpdate] = useState(false);
  const [isErrorUpdate, setIsErrorUpdate] = useState(false);
  const [isSuccessDelete, setIsSuccessDelete] = useState(false);
  const [isErrorDelete, setIsErrorDelete] = useState(false);
  const [errNetwork, setErrNetwork] = useState(false);
  useEffect(() => {
    sessionStorage.setItem(savedOpenDetail, openDetail);
    if (!openDetail)
      setInputAnnouncements({
        title: "",
        image: "",
        description: "",
      });
  }, [openDetail]);

  // FETCH

  useEffect(() => {
    setPage(1);
  }, [searchQuery]);

  const fetchAllAnnouncements = useCallback(
    async () => {
      if (isLoadingScroll || page > totalPage) return;
      setIsLoadingScroll(true);
      try {
        const { data: resultData } = await getAllAnnouncements(
          page,
          10,
          debounceQuery
        );

        if (debounceQuery) {
          setIsLoadingSearch(true);
          setData(resultData?.data);
          setTotalPage(resultData?.totalPage || 1);
          return;
        }

        const newData = resultData?.data || [];
        const updatedDataFetch = [
          ...data,
          ...newData.filter(
            (item) =>
              !data.some((existingItem) => existingItem._id === item._id)
          ),
        ];

        setData(updatedDataFetch);
        setTotalPage(resultData?.totalPage);
      } catch (error) {
        if (error?.code === "ERR_NETWORK") {
          setErrNetwork(true);
        }
        console.error("Error get data annoncement", error);
      } finally {
        setIsLoadingGet(false);
        setIsLoadingScroll(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, debounceQuery]
  );

  useEffect(() => {
    fetchAllAnnouncements();
  }, [fetchAllAnnouncements]);

  const dataTemplateAnnouncements = useMemo(() => {
    const temp = [...data];
    if (openDetail === "isCreate") {
      temp.unshift({
        _id: "isAdd",
        title: "Pengumuman Baru",
        body: "Belum disimpan",
        image: "", //a?.attachment.URI
      });
    }
    return (
      temp?.map((a) => ({
        id: a?._id,
        title: a?.title,
        description: a?.body,
        image: a?.attachment ? fileBaseUrl + a?.attachment.URI : null,
        uploadedAt: moment(a?.uploadedAt).format(`ll, HH:mm`),
        viewsCount: a?.totalViews,
      })) || []
    );
  }, [data, openDetail]);

  const uploadImageReff = useRef(null);
  const [inputAnnouncements, setInputAnnouncements] = useState(
    JSON.parse(sessionStorage.getItem(savedInputAnnouncements)) || {
      title: "",
      image: "",
      description: "",
    }
  );

  const handleChangeInput = (name, values) => {
    if (name === "deleteFile")
      return setInputAnnouncements((prev) => ({
        ...prev,
        image: "",
      }));
    else if (name === "image") {
      setInputAnnouncements((prev) => ({
        ...prev,
        image: values.target.files[0],
      }));
    } else
      setInputAnnouncements((prev) => ({
        ...prev,
        [name]: values,
      }));
  };

  useEffect(() => {
    const { title, description } = inputAnnouncements;
    const toSavedInput = {
      title: title,
      description: description,
    };
    if (openDetail === "isCreate") {
      sessionStorage.setItem(
        savedInputAnnouncements,
        JSON.stringify(toSavedInput)
      );
    }
  }, [inputAnnouncements, openDetail]);

  useEffect(() => {
    if (inputAnnouncements.image) {
      uploadImageReff.current.value = null;
    }
  }, [inputAnnouncements.image]);

  const dataToShow = useCallback(() => {
    return dataTemplateAnnouncements.find((d) => d?.id === openDetail);
  }, [dataTemplateAnnouncements, openDetail]);

  useEffect(() => {
    if (openDetail !== "isCreate" && openDetail)
      setInputAnnouncements((prev) => ({
        ...prev,
        title: dataToShow()?.title,
        description: dataToShow()?.description,
        image: dataToShow()?.image,
      }));
  }, [dataToShow, openDetail, dataTemplateAnnouncements]);

  //CREATE
  const addAnnouncements = async () => {
    setIsLoadingHit(true);
    try {
      const { description, image, title } = inputAnnouncements;
      const dataToCreate = new FormData();
      dataToCreate.append("title", title);
      dataToCreate.append("description", description);
      if (image) {
        dataToCreate.append("file", image);
      }

      const res = await createAnnouncement(dataToCreate);
      if (res.status === 201) {
        fetchAllAnnouncements();
        setIsSuccessCreate(true);
        setOpenDetail("");
        dataTemplateAnnouncements.shift();
        setInputAnnouncements({
          title: "",
          description: "",
          image: "",
        });
      }
    } catch (error) {
      console.log("Error create announcement:", error);
      setIsErrorCreate(true);
    } finally {
      setIsLoadingHit(false);
    }
  };
  // UPDATE
  const handleUpdateAnnouncements = async () => {
    setIsLoadingHit(true);
    try {
      const { description, image, title } = inputAnnouncements;
      const dataToUpdate = new FormData();
      dataToUpdate.append("title", title);
      dataToUpdate.append("description", description);
      if (!image) {
        dataToUpdate.append("delete", true);
      } else {
        dataToUpdate.append("file", image); // after update: delete the file return on BE isn't sync
      }
      const res = await upateAnnouncement(openDetail, dataToUpdate);
      if (res.status === 200) {
        fetchAllAnnouncements();
        setIsSuccessUpdate(true);
        setOpenDetail("");
        setInputAnnouncements({
          title: "",
          description: "",
          image: "",
        });
      }
    } catch (error) {
      console.log("Error update announcement:", error);
      setIsErrorUpdate(true);
    } finally {
      setIsLoadingHit(false);
    }
  };

  // DELETE
  const handleDeleteAnnouncement = async () => {
    setIsLoadingHit(true);
    try {
      const res = await deleteAnnouncement(openDetail);
      if (res.status === 200) {
        setIsSuccessDelete(true);
        fetchAllAnnouncements();
        setOpenDetail(dataTemplateAnnouncements[0]?.id);
      }
    } catch (error) {
      console.log("Error delete announcement", error);
      setIsErrorDelete(true);
    } finally {
      setIsLoadingHit(false);
    }
  };

  // VIEW
  const handleViewUpdate = useCallback(async () => {
    // const adminEmployeeID = localStorage.getItem("adminEmployeeID");
    // if (
    //   data?.find((e) =>
    //     e?.viewedBy?.find((a) => a?.employeeID === adminEmployeeID?.toString())
    //   )
    // )
    //   return;
    if (openDetail === "isCreate") return;
    try {
      await viewUpateAnnouncement(openDetail);
    } catch (error) {
      console.log("Error update view", error);
    }
  }, [openDetail]);

  useEffect(() => {
    if (!!openDetail) {
      handleViewUpdate();
    }
  }, [handleViewUpdate, openDetail]);

  const listInnerRef = useRef();
  const onScroll = useCallback(() => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        if (page < totalPage) {
          setIsLoadingSearch(false);
          setPage((p) => p + 1);
        }
      }
    }
  }, [page, totalPage]);

  useEffect(() => {
    if (height >= 768) {
      onScroll();
    }
  }, [height, onScroll]);

  const { setBreadcrumbsSPA } = useContext(SideBarStates);

  useEffect(() => {
    if (!!openDetail) {
      setBreadcrumbsSPA(true);
    } else {
      setBreadcrumbsSPA(false);
    }
  }, [openDetail, setBreadcrumbsSPA]);

  return (
    <>
      {errNetwork ? (
        <div className={Styles.errorContain}>
          <CustomEmptyHandlerPage
            title="ERR_INTERNET_DISCONNECTED"
            description="Network error: periksa koneksi internet anda, lalu muat ulang halaman"
            images={ImagesEmptyAnnouncements}
            btnText="Reload"
            icon={"repeat"}
            onClick={() => window.location.reload()}
          />
        </div>
      ) : (
        <section className={Styles.containerAnnouncementsPage}>
          <Toast
            isopen={isSuccessCreate || isErrorCreate}
            color={isSuccessCreate ? "green" : "red"}
            text={
              (isSuccessCreate ? "Berhasil" : "Gagal") +
              " menambahkan pengumuman"
            }
            onClose={() => {
              setIsSuccessCreate(false);
              setIsErrorCreate(false);
            }}
          />

          <Toast
            isopen={isSuccessUpdate || isErrorUpdate}
            color={isSuccessUpdate ? "green" : "red"}
            text={
              (isSuccessUpdate ? "Berhasil" : "Gagal") +
              " memperbarui pengumuman"
            }
            onClose={() => {
              setIsSuccessUpdate(false);
              setIsErrorUpdate(false);
            }}
          />

          <Toast
            isopen={isSuccessDelete || isErrorDelete}
            color={isSuccessDelete ? "green" : "red"}
            text={
              (isSuccessDelete ? "Berhasil" : "Gagal") + " Menghapus pengumuman"
            }
            onClose={() => {
              setIsSuccessDelete(false);
              setIsErrorDelete(false);
            }}
          />

          <div className={Styles.header}>
            {!!openDetail ? (
              <div className={Styles.backButton}>
                <Icon
                  icon={"arrow-chevron-left"}
                  color={"#3E4856"}
                  size={"20"}
                  className={Styles.iconBack}
                  onClick={() => {
                    setOpenDetail("");
                    if (openDetail === "isCreate") {
                      dataTemplateAnnouncements.shift();
                    }
                  }}
                />
                <h1>Detail Pengumuman</h1>
              </div>
            ) : isLoadingGet ? (
              <h1>
                <Skeleton w="200px" />
              </h1>
            ) : (
              <h1>Pengumuman</h1>
            )}

            {(data.length !== 0 || searchQuery || isLoadingGet) &&
              !Boolean(openDetail) && (
                <div style={{ opacity: isLoadingGet ? "0" : "1" }}>
                  <div className={Styles.searchBar}>
                    <SearchBar
                      placeholder="Cari pengumuman"
                      value={searchQuery}
                      setValue={setSearchQuery}
                    />
                  </div>
                  {isAdmin && (
                    <Button
                      text="Tambah Pengumuman"
                      onClick={() => setOpenDetail("isCreate")}
                      isAddButton
                      isLeftIcon
                    />
                  )}
                </div>
              )}

            {!searchQuery && openDetail === "isCreate" ? (
              <Button
                text="Simpan"
                isAddButton
                isLeftIcon
                icon={"tick-circle"}
                onClick={() => addAnnouncements()}
                style={{ padding: "8px 12px" }}
                isLoadingIndicator={isLoadingHit}
                isDisabled={
                  !inputAnnouncements.title || !inputAnnouncements.description
                }
              />
            ) : !!openDetail && openDetail !== "isCreate" && isAdmin ? (
              <div>
                <Button
                  text="Hapus Pengumuman"
                  style={{
                    color: "#d42701",
                    borderColor: "#d42701",
                    background: "transparent",
                  }}
                  colorIcon={"#d42701"}
                  isAddButton
                  isLeftIcon
                  onClick={() => handleDeleteAnnouncement()}
                />
                <Button
                  text="Simpan Perubahan"
                  isAddButton
                  isLeftIcon
                  icon={"tick-circle"}
                  onClick={() =>
                    isLoadingHit ? void {} : handleUpdateAnnouncements()
                  }
                  isLoadingIndicator={isLoadingHit}
                />
              </div>
            ) : isLoadingGet ? (
              <div>
                <Skeleton w="200px" />
                <Skeleton w="150px" />
              </div>
            ) : null}
          </div>

          <div className={`${Styles.mainContain} ${Styles[!!openDetail]}`}>
            <div
              className={`${Styles.left} ${Styles[!!openDetail]} `}
              style={
                !!openDetail && isLoadingGet ? { overflowX: "hidden" } : {}
              }
              ref={listInnerRef}
              onScroll={onScroll}
            >
              {isLoadingScroll && isLoadingSearch && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    bottom: "32vh",
                    left: !!openDetail ? "-22vw" : "0",
                    zIndex: "9999",
                  }}
                >
                  <Spinner width="60px" />
                </div>
              )}

              {!!openDetail && openDetail !== "isCreate" && !isLoadingGet && (
                <div className={Styles.headerSectionLeft}>
                  <div className={Styles.searchBar}>
                    <SearchBar
                      placeholder="Cari pengumuman"
                      className={Styles.stylesSearchBar}
                      value={searchQuery}
                      setValue={setSearchQuery}
                    />
                  </div>
                  {isAdmin && (
                    <Button
                      style={{
                        flexShrink: "0",
                        borderRadius: "unset",
                        padding: "20px 1rem",
                      }}
                      text="Tambah Pengumuman"
                      isAddButton
                      isLeftIcon
                      onClick={
                        openDetail !== "isCreate"
                          ? () => {
                              setOpenDetail("isCreate");
                              setInputAnnouncements({
                                title: "",
                                description: "",
                                image: "",
                              });
                            }
                          : () => void {}
                      }
                      isDisabled={Boolean(searchQuery)}
                    />
                  )}
                </div>
              )}
              {openDetail !== "isCreate" &&
                data?.length === 0 &&
                !debounceQuery &&
                !isLoadingGet &&
                !isLoadingScroll &&
                !isLoadingSearch && (
                  <div style={{ height: "100vh" }}>
                    <CustomEmptyHandlerPage
                      title="Belum Ada Data Pengumuman"
                      description="Silakan lakukan penambahan pengumuman untuk memberikan pengumuman kepada para personil"
                      btnText="Tambah Pengumuman"
                      images={ImagesEmptyAnnouncements}
                      onClick={() => setOpenDetail("isCreate")}
                      isAddButton={isAdmin}
                    />
                  </div>
                )}

              {data?.length === 0 && searchQuery && (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={
                      !!openDetail
                        ? { minWidth: "300px", minHeight: "300px" }
                        : { width: "100%", height: "100%" }
                    }
                  >
                    <CustomEmptyHandlerPage
                      title="Tidak Ada Data Pengumuman"
                      description={`Data yang anda cari "${searchQuery}" tidak ada dalam daftar pengumuman`}
                      isAddButton={false}
                      images={ImagesEmptyAnnouncements}
                    />
                  </div>
                </div>
              )}

              {(isLoadingGet
                ? Array.from({ length: 8 })
                : dataTemplateAnnouncements
              )?.map((el, idx) => (
                <div
                  className={`${Styles.viewRowBetween} ${
                    Styles[el?.id === "isAdd" || openDetail === el?.id]
                  }`}
                  onClick={() => {
                    setOpenDetail(el?.id);
                    if (openDetail === "isCreate") {
                      dataTemplateAnnouncements.shift();
                    }
                  }}
                  key={`list-announcements-${idx}`}
                >
                  {(el?.id === "isAdd" || openDetail === el?.id) && (
                    <div className={Styles.activeIndicator}></div>
                  )}
                  <div className={`${Styles.announcements}`}>
                    {isLoadingGet ? (
                      <BoxSkeleton h="60px" w="60px" />
                    ) : el?.image ? (
                      <img src={el?.image} alt="img" />
                    ) : (
                      <div className={Styles.defaultImg}>
                        <Icon
                          icon={"megaphone-outline"}
                          color={"#fff"}
                          size={"26"}
                        />
                      </div>
                    )}
                    {isLoadingGet ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "6px",
                          width: "100%",
                        }}
                      >
                        <Skeleton w="15vw" h="24px" />
                        <Skeleton w={!!openDetail ? "20vw" : "40vw"} h="16px" />
                        <Skeleton w={!!openDetail ? "30vw" : "50vw"} h="16px" />
                      </div>
                    ) : (
                      <div>
                        <h2>{el?.title}</h2>
                        <p
                          dangerouslySetInnerHTML={{ __html: el?.description }}
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className={`${Styles.createdAt} ${Styles[!!openDetail]} `}
                    style={el?.id === "isAdd" ? { position: "relative" } : {}}
                  >
                    <div
                      className={`${Styles.date} ${
                        el?.id === "isAdd" && Styles.dNone
                      } `}
                    >
                      {isLoadingGet ? (
                        <Skeleton w={!!openDetail ? "60px" : "200px"} />
                      ) : (
                        <span> Diunggah pada {el?.uploadedAt}</span>
                      )}
                      {isLoadingGet ? (
                        <Skeleton w={!!openDetail ? "40px" : "100px"} />
                      ) : (
                        <div className={Styles.views}>
                          <Icon icon={"eye"} color={"#A9B3C1"} size={"16"} />
                          <span>{el?.viewsCount}</span>
                        </div>
                      )}
                    </div>

                    <Icon
                      icon={"arrow-chevron-right"}
                      color={
                        el?.id === "isAdd" || openDetail === el?.id
                          ? "#2C5364"
                          : "#A9B3C1"
                      }
                      size={"16"}
                      className={Styles.arrowrightIcon}
                      style={
                        el?.id === "isAdd"
                          ? { position: "absolute", right: "0" }
                          : {}
                      }
                    />
                  </div>
                </div>
              ))}
            </div>

            <div
              className={`${Styles.right} ${Styles[!!openDetail]} `}
              style={{ position: "relative" }}
            >
              <div className={Styles.headerRight}>
                <InputField
                  isRequired
                  isLabel
                  textLabel="Judul Pengumuman"
                  placeholder="Masukkan judul pengumuman"
                  setValue={(e) => handleChangeInput("title", e)}
                  value={inputAnnouncements.title}
                />
              </div>
              <div className={Styles.divider}></div>
              <div className={Styles.inputFiles}>
                <label htmlFor="files">Lampiran</label>
                <input
                  type="file"
                  onChange={(e) => handleChangeInput("image", e)}
                  id="files"
                  hidden
                  accept=".jpg, .png, .jpeg"
                  ref={uploadImageReff}
                />
                {inputAnnouncements.image ? (
                  <div className={Styles.showedImage}>
                    <img
                      src={
                        // openDetail === "isCreate" &&
                        // dataTemplateAnnouncements.some((el) => el?.id === "isAdd")
                        //   ? URL.createObjectURL(inputAnnouncements.image)
                        //   : dataTemplateAnnouncements.find(
                        //       (a) =>
                        //         a?.id === openDetail &&
                        //         a?.image !== inputAnnouncements.image &&
                        //         a?.image !== null
                        //       // inputAnnouncements.image !== "")
                        //     )
                        //   ? URL.createObjectURL(inputAnnouncements.image)
                        //   : inputAnnouncements.image
                        typeof inputAnnouncements.image === "object"
                          ? URL.createObjectURL(inputAnnouncements.image)
                          : inputAnnouncements.image
                      }
                      alt={inputAnnouncements.image?.name}
                    />
                    <div
                      className={Styles.iconDelete}
                      onClick={() => handleChangeInput("deleteFile")}
                    >
                      <Icon icon={"close-circle"} color={"#fff"} size={"8"} />
                    </div>
                  </div>
                ) : (
                  <div
                    className={Styles.uploadFile}
                    onClick={() => uploadImageReff.current.click()}
                  >
                    <Icon icon={"add-circle"} color={"#A9B3C1"} size={"24"} />
                  </div>
                )}
              </div>
              <div className={Styles.dividerTextEditor}></div>

              <div className={Styles.editorTools}>
                <TextEditor
                  type="inline"
                  setValue={(e) => handleChangeInput("description", e)}
                  value={inputAnnouncements.description}
                  isSticky
                  placeholder={"Masukkan deskripsi pengumuman..."}
                />
              </div>
            </div>
            {isLoadingScroll && !searchQuery && !isLoadingSearch && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  bottom: "1rem",
                  left: !!openDetail ? "-22vw" : "0",
                  zIndex: "9999",
                }}
              >
                <Spinner />
              </div>
            )}
          </div>
        </section>
      )}
    </>
  );
}
