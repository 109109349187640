import React, {
  Fragment,
  Suspense,
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from "react";
import Styles from "./style.module.scss";
import CustomEmptyHandlerPage from "@Atom/CustomEmptyHandlerPage";
import ModalAddLocation from "@Molecule/_modal/AddLocation";
import { useLocation, useNavigate } from "react-router-dom";
import Icon from "@Atom/Icon";
import ModalStatusReport from "../Activity/ModalStatus";
import { fileBaseUrl } from "configs";
import useWindowSize from "@Hooks/useWindowSize";
import Spinner from "@Atom/Spinner";
import useQuery from "@Hooks/useQuery";
import { capitalizeEachWord } from "helpers/capitalizeEachWord";
import Images from "@Theme/Images";
import moment from "moment";

export default function ReportActivity({
  dataCounter,
  dataAllLocation,
  dataStandBy,
  dataAlert,
}) {
  const isAdmin = localStorage.getItem("role") === "ADMIN";
  const { pathname } = useLocation();
  const { height } = useWindowSize();
  const [isOpenModalAddReport, setIsOpenModalAddReport] = useState();
  const navigate = useNavigate();
  const query = useQuery();
  const cardTemplate = useMemo(() => {
    return dataAllLocation?.data?.map((l) => ({
      id: l?._id,
      image: fileBaseUrl + l?.imageURI,
      name: capitalizeEachWord(l?.name),
      personil: l?.memberCount || 0,
    }));
  }, [dataAllLocation?.data]);

  const [isOpenAddLocation, setIsOpenAddLocation] = useState(false);
  const [isOpenStatusAlert, setIsOpenStatusAlert] = useState(false);
  const [isOpenStatusStandBy, setIsOpenStatusStandBy] = useState(false);

  const [limit, setLimit] = useState(10);
  const limitQuery = +query.get("limit");
  const [isTransition, setTransition] = useState(false);

  useEffect(() => {
    if (isOpenStatusAlert || isOpenStatusStandBy) {
      setTimeout(() => {
        setTransition(true);
      }, 100);
    } else {
      setTransition(false);
    }
  }, [isOpenStatusAlert, isOpenStatusStandBy]);

  const listInnerRef = useRef(null);

  const onScroll = useCallback(() => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        if (limit < dataAllLocation?.totalData) {
          setLimit((prevLimit) => prevLimit + 10);
        }
      }
    }
  }, [dataAllLocation?.totalData, limit]);

  useEffect(() => {
    if (height >= 768) {
      onScroll();
    }
  }, [height, onScroll]);

  useEffect(() => {
    navigate(`${pathname}?page=1&limit=${limit}`);
  }, [limit, navigate, pathname]);

  const dataStandByReportMonthly = useMemo(() => {
    return dataStandBy?.data?.map((s) => ({
      id: s?._id,
      name: capitalizeEachWord(s?.employee.name),
      profilePicture: s?.employee?.profilePicture || Images.AVA_DEFAULT,
      date: `${moment(s?.submittedAt).format("dddd")}, ${moment(
        s?.submittedAt
      ).format("LL")}`,
      time: `${moment(s?.submittedAt).format("LTS").split(".").join(":")} WIB`,
      shift: `${
        s?.shift === "P" ? "Pagi" : s?.shift === "S" ? "Sore" : "Malam"
      }`,
      activity: capitalizeEachWord(s?.title),
      statusLocation: capitalizeEachWord(s?.status),
      images: s?.images,
      reportDescription: s?.description,
      location: capitalizeEachWord(s?.guardpoint?.name),
    }));
  }, [dataStandBy?.data]);

  const dataAlertReportMonthly = useMemo(() => {
    return dataAlert?.data?.map((a) => ({
      id: a?._id || "-",
      name: capitalizeEachWord(a?.employee.name) || "-",
      profilePicture: a?.employee?.profilePicture || Images.AVA_DEFAULT || "-",
      date:
        `${moment(a?.submittedAt).format("dddd")}, ${moment(
          a?.submittedAt
        ).format("LL")}` || "-",
      time:
        `${moment(a?.submittedAt).format("LTS").split(".").join(":")} WIB` ||
        "-",
      shift:
        `${a?.shift === "P" ? "Pagi" : a?.shift === "S" ? "Sore" : "Malam"}` ||
        "-",
      activity: capitalizeEachWord(a?.title) || "-",
      statusLocation: capitalizeEachWord(a?.status) || "-",
      images: a?.images || "-",
      reportDescription: a?.description || "-",
      location: capitalizeEachWord(a?.guardpoint?.name) || "-",
    }));
  }, [dataAlert?.data]);

  return (
    <div className={Styles.containerReportActivity}>
      {dataAllLocation?.data?.length === 0 ? (
        <>
          <CustomEmptyHandlerPage
            description="Silahkan lakukan penambahan lokasi kerja terlebih dahulu untuk melihat laporan aktifitas"
            onClick={() => setIsOpenModalAddReport(true)}
            isAddButton={isAdmin}
          />
          {isOpenModalAddReport && (
            <ModalAddLocation
              isOpen
              isFilled
              onClose={() => setIsOpenModalAddReport(false)}
            />
          )}
        </>
      ) : (
        <div
          className={Styles.mainContainListReport}
          ref={listInnerRef}
          onScroll={onScroll}
          style={{ height: `calc(${height}px - 56px)` }}
        >
          <strong>Laporan Aktifitas</strong>

          <div className={Styles.informationPersonil}>
            <div className={Styles.card}>
              <div>
                <div style={{ background: "#F9ECD3", borderColor: "#E78B00" }}>
                  <Icon icon={"Information"} color={"#E78B00"} size={"32"} />
                </div>
                <div>
                  <span>Status Siaga (Bulan Ini)</span>
                  <h3>{dataCounter?.data.siaga || 0}</h3>
                </div>
              </div>
              <div onClick={() => setIsOpenStatusStandBy(true)}>
                <Icon
                  icon={"maximize"}
                  color={"#2C5364"}
                  size={"20"}
                  className={Styles.icon}
                />
              </div>
            </div>
            <div
              className={Styles.card}
              style={{ borderBottom: "3px solid #D42701" }}
            >
              <div>
                <div style={{ background: "#F2E5E2" }}>
                  <Icon
                    icon={"information-alert"}
                    color={"#D42701"}
                    size={"32"}
                  />
                </div>
                <div>
                  <span>Status Waspada (Bulan Ini)</span>
                  <h3>{dataCounter?.data.waspada || 0}</h3>
                </div>
              </div>
              <div onClick={() => setIsOpenStatusAlert(true)}>
                <Icon
                  icon={"maximize"}
                  color={"#2C5364"}
                  size={"20"}
                  className={Styles.icon}
                />
              </div>
            </div>
          </div>

          {isOpenStatusStandBy && (
            <ModalStatusReport
              isTransition={isTransition}
              onClose={() => {
                setIsOpenStatusStandBy(false);
              }}
              isStandBy={isOpenStatusStandBy}
              textHead={"Daftar Laporan Dengan Status Siaga"}
              templateData={dataStandByReportMonthly}
            />
          )}

          {isOpenStatusAlert && (
            <ModalStatusReport
              isTransition={isTransition}
              onClose={() => {
                setIsOpenStatusAlert(false);
              }}
              textHead={"Daftar Laporan Dengan Status Waspada"}
              templateData={dataAlertReportMonthly}
            />
          )}

          <div className={Styles.cardLocation}>
            {cardTemplate?.map((el, idx) => (
              <Fragment key={idx}>
                <div className={Styles.card}>
                  <img alt="image-location" src={el?.image} />
                  <div className={Styles.description}>
                    <div className={Styles.personil}>
                      <strong>{el?.name}</strong>
                      <div>
                        <Icon icon={"people"} className={Styles.peopleIcon} />
                        <span>{el?.personil} Personil</span>
                      </div>
                    </div>
                    <div
                      className={Styles.btnCard}
                      onClick={() => {
                        navigate(`detail/${el?.id}`);
                        sessionStorage.setItem(
                          "locationName",
                          capitalizeEachWord(el?.name)
                        );
                      }}
                    >
                      <span>Lihat Laporan</span>
                      <Icon icon={"arrow-back"} className={Styles.arrowIcon} />
                    </div>
                  </div>
                </div>
              </Fragment>
            ))}
            {limit !== limitQuery && <IndicatorScrollLoader />}
          </div>

          {isOpenAddLocation && (
            <Suspense fallback={<p>Loading component...</p>}>
              <ModalAddLocation
                isOpen={isOpenAddLocation}
                onClose={() => setIsOpenAddLocation(false)}
              />
            </Suspense>
          )}
        </div>
      )}
    </div>
  );
}

const IndicatorScrollLoader = () => {
  return (
    <div className={Styles.wrapLoaderScroll}>
      <Spinner />
    </div>
  );
};
