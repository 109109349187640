import React, { useEffect, useMemo, useRef, useState } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import { useLocation, useNavigate } from "react-router-dom";
import Map from "@Molecule/Map";
import Geocode from "react-geocode";
import ModalAddLocation from "@Molecule/_modal/AddLocation";
import { editWorkInfoLocation } from "@Services/workLocation/editInfoWorkLocation";
import Toast from "@Atom/Toast";
import { getAllSchemaByClientCode } from "@Services/attendenceSchema/getAllSchemaByClientCode";
import LoadingSpinner from "@Molecule/LoadingSpinner";
import { capitalizeEachWord } from "helpers/capitalizeEachWord";
import TabHeader from "@Atom/TabHeader";
import Table from "@Molecule/Table";
import Images from "@Theme/Images";
import { fileBaseUrl } from "configs";
import useQuery from "@Hooks/useQuery";
import CustomDatePicker from "@Atom/CustomDatePicker";
import Button from "@Atom/Button";
import moment from "moment";

export default function DetailLocation({ data }) {
  const isAdmin = localStorage.getItem("role") === "ADMIN";
  const CLIENT_KEY = JSON.parse(localStorage.getItem("currentClient"));
  const query = useQuery();
  const { pathname } = useLocation();
  useEffect(() => {
    sessionStorage.setItem(
      "nameDetailInfoWorkLocation",
      capitalizeEachWord(data?.name)
    );
  }, [data?.name]);

  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
  Geocode.setLanguage("id");
  Geocode.setRegion("ID");

  const navigate = useNavigate();
  const [isOpenInfoLocation, setIsOpenInfoLocation] = useState(false);
  const templateProgress = useMemo(() => {
    return [
      {
        id: 1,
        progressPatrol: data?.patrolProgress,
      },
      {
        id: 2,
        progressPatrol: data?.activityProgress,
      },
    ];
  }, [data?.activityProgress, data?.patrolProgress]);

  const [coordinate, setCoordinateAdd] = useState({
    latitude: data?.latitude,
    longitude: data?.longitude,
  });

  useEffect(() => {
    Geocode.fromLatLng(coordinate.latitude, coordinate.longitude).then(
      (respose) => {},
      (error) => {
        console.error("Geocode error", error);
      }
    );
    setCoordinateAdd({
      latitude: parseFloat(coordinate.latitude) || -6.2,
      longitude: parseFloat(coordinate.longitude) || 106.816666,
    });
  }, [coordinate.latitude, coordinate.longitude]);

  const [loadingPost, setLoadingPost] = useState(false);

  const [toastStatus, setToastStatus] = useState({
    success: false,
    error: false,
  });

  const [selectedLocation, setSelectedLocation] = useState({
    latitude: data?.latitude,
    longitude: data?.longitude,
  });

  const [inputLocation, setInputLocation] = useState({
    image: "",
    name: "",
    nameLead: "",
    leadPhoneNumber: "",
    address: "",
    guardRadius: "",
    schemaAbsence: "",
    latitude: "",
    longitude: "",
  });

  useEffect(() => {
    setInputLocation({
      image: data?.imageURI,
      name: data?.name,
      nameLead: data?.pic.name,
      leadPhoneNumber: data?.pic.phoneNumber,
      address: data?.address,
      guardRadius: data?.radius,
      schemaAbsence: { list: data.schema?.name, id: data.schema?._id },
      latitude: data?.latitude,
      longitude: data?.longitude,
    });
    setSelectedLocation({
      latitude: data?.latitude,
      longitude: data?.longitude,
    });
  }, [data]);

  const fileRef = useRef(null);

  const [selectedDropdownID, setSelectedDropdownID] = useState(data.schema?.ID);

  const [dataListDropdown, setDataListDropdown] = useState([
    {
      name: "",
      id: "",
    },
    {
      name: "",
      id: "",
    },
  ]);

  const getDataSchemaByClientCode = async () => {
    try {
      const res = await getAllSchemaByClientCode();
      setDataListDropdown(
        res.data.map((el) => ({
          list: el.name,
          id: el._id,
        }))
      );
    } catch (error) {
      console.log("error get schema", error);
    }
  };

  useEffect(() => {
    getDataSchemaByClientCode();
  }, []);

  const handleSubmit = async () => {
    setLoadingPost(true);
    const formData = new FormData();
    formData.append("name", inputLocation.name?.trim());
    formData.append("address", inputLocation.address?.trim());
    formData.append("pic[name]", inputLocation.nameLead?.trim());
    formData.append("pic[phoneNumber]", inputLocation.leadPhoneNumber?.trim());
    formData.append("radius", inputLocation.guardRadius?.trim());
    formData.append("latitude", selectedLocation.latitude);
    formData.append("longitude", selectedLocation.longitude);
    formData.append("client[ID]", CLIENT_KEY.id);
    formData.append("client[name]", CLIENT_KEY?.name);
    formData.append("client[code]", CLIENT_KEY?.code);
    formData.append("file", inputLocation.image);
    formData.append("schema[ID]", selectedDropdownID);
    formData.append("schema[name]", inputLocation.schemaAbsence.list);
    try {
      const res = await editWorkInfoLocation(data?._id, formData);
      if (res.status === 200) {
        setToastStatus((prev) => ({
          ...prev,
          success: true,
        }));
        setLoadingPost(false);
        setInputLocation({});
        setIsOpenInfoLocation(false);
        navigate(pathname, {
          replace: true,
        });
      }
    } catch (error) {
      console.log(`error edit info location: ${error}`);
      setLoadingPost(false);
      setToastStatus((prev) => ({
        ...prev,
        error: true,
      }));
    }
  };

  const dateQuery = query?.get("date");

  const [selectedDate, setSelectedDate] = useState(dateQuery);

  useEffect(() => {
    const convertedDate = moment(selectedDate).format("YYYY-MM-DD");
    if (convertedDate !== dateQuery)
      navigate(`${pathname}?query=q&date=${convertedDate}`);
  }, [navigate, pathname, selectedDate, dateQuery]);

  const loadingdFetchMore = () => {
    if (moment(selectedDate).format("YYYY-MM-DD") !== dateQuery) {
      return true;
    } else return false;
  };

  const templateDataField = useMemo(() => {
    return {
      data:
        data?.handoverData?.items?.map((f) => ({
          id: f?._id,
          name: capitalizeEachWord(f?.name) || 0,
          profilePicture: Images.AVA_DEFAULT, // currently no images
          image: fileBaseUrl + f?.url || 0,
          leader:
            capitalizeEachWord(data?.handoverData?.submittedBy?.name) || "-", // di satuin
          amount: f?.amount || 0,
        })) || [],

      columns: [
        {
          name: "name",
          title: <TabHeader text="Nama Barang" />,
          renderData: (row) => (
            <div className={`${Styles.imgAndName} ${Styles.isProfilePic}`}>
              <img src={row.image} alt="" />
              <span>{row?.name}</span>
            </div>
          ),
        },
        {
          name: "leader",
          title: <TabHeader text="Penanggung Jawab Terakhir" />,
          renderData: (row) => (
            <div className={`${Styles.imgAndName} `}>
              <img
                className={`${Styles.isProfilePic}`}
                src={row.profilePicture}
                alt=""
              />
              <span>{row.leader}</span>
            </div>
          ),
          hideOnMobile: true,
        },
        {
          name: "amount",
          title: <TabHeader text="Jml Barang" />,
          renderData: (row) => <span>{row?.amount}</span>,
        },
      ],
    };
  }, [data?.handoverData]);

  return (
    <div className={Styles.containerDetailLocation}>
      {isOpenInfoLocation && (
        <ModalAddLocation
          isOpen={isOpenInfoLocation}
          isFilled
          onClose={() => setIsOpenInfoLocation(false)}
          inputLocation={inputLocation}
          setInputLocation={setInputLocation}
          fileRef={fileRef}
          onSubmit={() => handleSubmit()}
          setSelectedLocation={setSelectedLocation}
          selectedLocation={selectedLocation}
          latitude={data.latitude}
          longitude={data.longitude}
          dataListDropdown={dataListDropdown}
          setSelectedDropdownID={setSelectedDropdownID}
          isDisabled={loadingPost}
        />
      )}
      <header className={Styles.header}>
        <div>
          <Icon
            icon={"arrow-chevron-left"}
            onClick={() => navigate("/work-location")}
            className={Styles.back}
          />
          <h3>{capitalizeEachWord(data?.name)}</h3>
        </div>
        {isAdmin && (
          <Icon
            icon={"setting-2"}
            className={Styles.settings}
            onClick={() => setIsOpenInfoLocation(true)}
          />
        )}
      </header>
      <section className={Styles.viewerMaps}>
        <Map
          coordinate={{
            latitude: data.latitude,
            longitude: data.longitude,
          }}
          zoom={14}
          options={{
            mapTypeControl: true,
            zoomControl: true,
          }}
          isRadius
          radius={+data?.radius}
          isPreview
          height="300px"
        />
      </section>
      <div className={Styles.informationPersonil}>
        <div className={Styles.card}>
          <div>
            <Icon icon={"people"} color={"#2C5364"} size={"32"} />
          </div>
          <div>
            <span>Jumlah Personil</span>
            <h3>{data?.memberCount || 0}</h3>
          </div>
        </div>
        <div
          className={Styles.card}
          style={{ borderBottom: "3px solid #22970F" }}
        >
          <div style={{ background: "#E4F8E0" }}>
            <Icon icon={"people"} color={"#22970F"} size={"32"} />
          </div>
          <div>
            <span>Jumlah Personil Bertugas Hari Ini</span>
            <h3>{data?.memberOnSite || 0}</h3>
          </div>
        </div>
        <div
          className={Styles.card}
          style={{ borderBottom: "3px solid #D42701" }}
        >
          <div style={{ background: "#F2E5E2" }}>
            <Icon icon={"people"} color={"#D42701"} size={"32"} />
          </div>
          <div>
            <span>Panggilan Darurat (30 Hari Terakhir)</span>
            <h3>{data?.emergencyCounter || 0}</h3>
          </div>
        </div>
      </div>

      {(toastStatus.error || toastStatus.success) && (
        <Toast
          isopen
          text={
            toastStatus.error
              ? "Gagal mengubah lokasi"
              : "Berhasil mengubah lokasi"
          }
          color={toastStatus.error ? "red" : "green"}
          onClose={() =>
            setToastStatus((prev) => ({
              ...prev,
              error: false,
              success: false,
            }))
          }
        />
      )}

      {loadingPost && <LoadingSpinner text="Sedang menyimpan..." />}

      <div className={Styles.cardProgress}>
        {templateProgress.map((el, idx) => (
          <div className={Styles.card} key={idx}>
            <strong>
              {el?.id === 2
                ? "Progres Aktifitas (Hari Ini)"
                : "Progres Patroli (Hari Ini)"}{" "}
            </strong>
            <div className={Styles.borderProgress}>
              <svg
                width="313"
                height="313"
                viewBox="0 0 36 36"
                className={Styles.circularChart}
              >
                <path
                  className={Styles.circleBg}
                  strokeDasharray="50"
                  d="M18 2.0845
                 a 15.9155 15.9155 0 0 1 0 31.831
                 a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <path
                  className={Styles.circle}
                  strokeDasharray={`${(el.progressPatrol / 50) * 25}, 100`}
                  d="M18 2.0845
                 a 15.9155 15.9155 0 0 1 0 31.831
                 a 15.9155 15.9155 0 0 1 0 -31.831"
                />
              </svg>
              <div className={Styles.descPercentage}>
                <strong>{el.progressPatrol}/100</strong>
                <span>
                  {el?.id === 2
                    ? "Aktivitas Diselesaikan"
                    : "Patroli Diselesaikan"}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={Styles.tableRadius}>
        <div className={Styles.header}>
          <span>Daftar Barang Temuan</span>
          <div>
            <CustomDatePicker
              isForModal
              setValue={setSelectedDate}
              value={selectedDate}
              autoSave
            >
              <Button
                icon={"calendar"}
                colorIcon={"#2C5364"}
                style={{
                  background: "transparent",
                  color: "#2C5364",
                  paddingBlock: "4px",
                }}
                text={moment(selectedDate).format("LL")}
              />
            </CustomDatePicker>
            <form action="">
              <button type="submit">
                <div className={Styles.refresh}>
                  <Icon icon={"repeat"} color={"#2C5364"} size={"14"} />
                </div>
              </button>
            </form>
          </div>
        </div>
        <Table
          className={Styles.table}
          data={templateDataField.data}
          columns={templateDataField.columns}
          totalData={templateDataField.data.length}
          withNumbering
          isDetailChart={loadingdFetchMore()}
          limit={
            templateDataField.data.length <= 10
              ? 10
              : templateDataField.data.length
          }
          isLoading={loadingdFetchMore()}
        />
      </div>
    </div>
  );
}
