import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import SearchBar from "@Atom/SearchBar";
import { useNavigate, useParams } from "react-router-dom";
import ModalAddGuardPos from "@Molecule/_modal/AddGuardPos";
import { fileBaseUrl } from "configs";
import { createGuardPost } from "@Services/guardPost/createGuardPost";
import Toast from "@Atom/Toast";
import Button from "@Atom/Button";
import GuardPostIcon from "@Assets/Images/guard-post-icon.png";
import PDFBarcodeDownload from "@Molecule/PDFBarcode";
import useWindowSize from "@Hooks/useWindowSize";
import CustomEmptyHandlerPage from "@Atom/CustomEmptyHandlerPage";
import Images from "@Theme/Images";
import LoadingSpinner from "@Molecule/LoadingSpinner";
import { capitalizeEachWord } from "helpers/capitalizeEachWord";
import Spinner from "@Atom/Spinner";
import { getAllGuardPost } from "@Services/guardPost/getAllGuardPost";
import { useDebounce } from "@Hooks/useDebounce";
import Skeleton, { BoxSkeleton } from "@Atom/Skeleton";
export default function ListGuardPos({ isLoader = true }) {
  const isAdmin = localStorage.getItem("role") === "ADMIN";
  const [data, setData] = useState({ data: [] });
  const { id: locationID } = useParams();
  const navigate = useNavigate();
  const [isOpenAddPoint, setIsOpenAddPoint] = useState(false);
  const [isLoadingScroll, setIsLoadingScroll] = useState(false);
  const [isInitialLoader, setIsInitialLoader] = useState(isLoader);
  const { height, width } = useWindowSize();
  const limit = 10;
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const debounceQuery = useDebounce(searchQuery, 500);
  const [totalPage, setTotalPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [searchQuery]);

  const fetchMoreData = useCallback(async () => {
    if (isLoadingScroll || page > totalPage) return;

    setIsLoadingScroll(true);

    try {
      if (debounceQuery && page === 1) {
        setData({ data: [] });
      }

      const res = await getAllGuardPost(page, limit, locationID, debounceQuery);

      if (debounceQuery) {
        setData({ data: res?.data });
        setTotalPage(res?.totalPage || 1);
        return;
      }

      const newData = res?.data || [];
      const updatedData = [
        ...data.data,
        ...newData.filter(
          (item) =>
            !data.data.some((existingItem) => existingItem._id === item._id)
        ),
      ];

      setData({ data: updatedData });
      setTotalPage(res?.totalPage || 1);
    } catch (error) {
      console.error("Error fetching list patrol:", error);
    } finally {
      setIsLoadingScroll(false);
      setIsInitialLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, debounceQuery, locationID]);

  useEffect(() => {
    fetchMoreData();
  }, [fetchMoreData]);

  const [inputLocation, setInputLocation] = useState({
    image: "",
    name: "",
    personil: "",
    fieldPosInventory: [
      {
        id: "123",
        nameInventory: "",
        amount: "",
      },
      {
        id: "234",
        nameInventory: "",
        amount: "",
      },
    ],
    guardRadius: "",
    description: "",
  });
  const [selectedLocation, setSelectedLocation] = useState({
    latitude: +data?.latitude || 0,
    longitude: +data?.longitude || 0,
  });
  const fileRef = useRef(null);

  const [loadingPost, setLoadingPost] = useState(false);

  const [toastStatus, setToastStatus] = useState({
    success: false,
    error: false,
  });

  useEffect(() => {
    const requiredFields = [
      inputLocation.image,
      inputLocation.name,
      inputLocation.personil,
      inputLocation.guardRadius,
      inputLocation.description,
      selectedLocation.latitude,
      selectedLocation.longitude,
      inputLocation.fieldPosInventory.every((f) => f.nameInventory && f.amount),
    ];

    const isAnyFieldEmpty = requiredFields.some((field) => !field);
    setIsDisabledButton(isAnyFieldEmpty);
  }, [inputLocation, selectedLocation]);

  const handleSubmit = async () => {
    setLoadingPost(true);
    setIsOpenAddPoint(false);
    const formData = new FormData();
    formData.append("locationID", locationID);
    formData.append("file", inputLocation.image);
    formData.append("name", inputLocation.name?.trim());
    formData.append("totalPersonnel", inputLocation.personil);

    for (let f = 0; f < inputLocation.fieldPosInventory.length; f++) {
      const inventory = inputLocation.fieldPosInventory[f];
      formData.append(`inventories[${f}][name]`, inventory.nameInventory);
      formData.append(`inventories[${f}][amount]`, inventory.amount);
    }

    formData.append("description", inputLocation.description?.trim());
    formData.append("latitude", selectedLocation.latitude);
    formData.append("longitude", selectedLocation.longitude);
    formData.append("radius", inputLocation.guardRadius);

    try {
      const res = await createGuardPost(formData);
      if (res.status === 201) {
        setToastStatus((prev) => ({
          ...prev,
          success: true,
        }));
        setLoadingPost(false);
        setInputLocation({
          image: "",
          name: "",
          personil: "",
          fieldPosInventory: [
            {
              id: "123",
              nameInventory: "",
              amount: "",
            },
            {
              id: "234",
              nameInventory: "",
              amount: "",
            },
          ],
          guardRadius: "",
          description: "",
        });
        fetchMoreData();
      }
    } catch (error) {
      console.log(`error add location: ${error}`);
      setLoadingPost(false);
      setToastStatus((prev) => ({
        ...prev,
        error: true,
      }));
    }
  };

  const listInnerRef = useRef();
  const onScroll = useCallback(() => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        if (page < totalPage) {
          setPage((p) => p + 1);
        }
      }
    }
  }, [page, totalPage]);

  useEffect(() => {
    if (height >= 768) {
      onScroll();
    }
  }, [height, onScroll]);

  useEffect(() => {
    if (isInitialLoader) {
      const preventClick = (event) => {
        const pageContainer = document.getElementById("list-guard-post-page");
        if (pageContainer && pageContainer.contains(event.target)) {
          event.preventDefault();
          event.stopImmediatePropagation();
        }
      };
      document.addEventListener("click", preventClick, true);

      return () => {
        document.removeEventListener("click", preventClick, true);
      };
    }
  }, [isInitialLoader]);

  return (
    <>
      {(toastStatus.error || toastStatus.success) && (
        <Toast
          isopen
          text={
            toastStatus.error
              ? "Gagal Menambahkan Lokasi"
              : "Berhasil Menambahkan Lokasi"
          }
          color={toastStatus.error ? "red" : "green"}
          onClose={() =>
            setToastStatus((prev) => ({
              ...prev,
              error: false,
              success: false,
            }))
          }
        />
      )}

      {loadingPost && !isOpenAddPoint && (
        <LoadingSpinner text="Sedang menyimpan..." />
      )}

      <div className={Styles.wrapperListPatrolPoint} id="list-guard-post-page">
        {isOpenAddPoint && (
          <ModalAddGuardPos
            isOpen={isOpenAddPoint}
            onClose={() => setIsOpenAddPoint(false)}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            inputLocation={inputLocation}
            fileRef={fileRef}
            onSubmit={() => handleSubmit()}
            setInputLocation={setInputLocation}
            isDisabled={isDisabledButton}
          />
        )}

        <div
          className={`${Styles.mainContainListPatrolPoint} ${
            data?.data?.length === 0 && Styles.isEmpty
          } `}
        >
          <div className={Styles.between}>
            <div className={Styles.back}>
              <Icon
                icon={"arrow-chevron-left"}
                size={"20"}
                className={Styles.backIcon}
                onClick={() => navigate("/work-location")}
              />
              <span>
                {capitalizeEachWord(
                  sessionStorage.getItem("nameDetailInfoWorkLocation")
                )}
              </span>
            </div>
            {isAdmin && (
              <Button
                text="Tambah Pos Jaga"
                onClick={() => setIsOpenAddPoint(true)}
                isLeftIcon
              />
            )}
          </div>
          {data?.data?.length === 0 && !searchQuery && !isInitialLoader ? (
            <CustomEmptyHandlerPage
              title="Lokasi Pos Jaga Belum Ditambahkan"
              description="Silahkan lakukan penambahan lokasi pos jaga"
              btnText="Tambah Lokasi Pos Jaga"
              images={Images.RAILWAY_STATION}
              onClick={() => setIsOpenAddPoint(true)}
              isAddButton={isAdmin}
            />
          ) : (
            <div className={Styles.viewFilter}>
              <SearchBar value={searchQuery} setValue={setSearchQuery} />
            </div>
          )}

          {data?.data?.length === 0 && searchQuery ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "60vh",
                alignItems: "center",
              }}
            >
              <CustomEmptyHandlerPage
                title="Data Tidak Ditemukan"
                description={`Tidak ada nama lokasi untuk hasil pencarian ${searchQuery}`}
                btnText="Tambah Lokasi Pos Jaga"
                images={Images.RAILWAY_STATION}
                isAddButton={false}
              />
            </div>
          ) : (
            <div
              className={Styles.cardLocation}
              onScroll={onScroll}
              ref={listInnerRef}
              style={{ maxHeight: "100%", overflowY: "auto" }}
            >
              {(isInitialLoader ? Array.from({ length: 10 }) : data?.data).map(
                (el, idx) => (
                  <Fragment key={isInitialLoader ? idx : el?._id}>
                    <div className={Styles.card}>
                      <div className={Styles.img}>
                        {isInitialLoader ? (
                          <BoxSkeleton w="260px" h="100%" />
                        ) : (
                          <img
                            alt="image-location"
                            src={fileBaseUrl + el?.imageURI}
                          />
                        )}
                      </div>
                      <div className={Styles.description}>
                        <div className={Styles.personil}>
                          {isInitialLoader ? (
                            <Skeleton w="200px" />
                          ) : (
                            <strong>{capitalizeEachWord(el?.name)}</strong>
                          )}
                          {isInitialLoader ? (
                            <Skeleton w="120px" />
                          ) : (
                            <div>
                              <img
                                src={GuardPostIcon}
                                className={Styles.peopleIcon}
                                alt="icon"
                              />
                              <span>{el?.totalPersonnel} Personil jaga</span>
                            </div>
                          )}
                        </div>
                        {isInitialLoader ? (
                          <Skeleton w="80%" />
                        ) : (
                          <div
                            className={Styles.btnCard}
                            onClick={() => {
                              navigate(el?._id);
                              sessionStorage.setItem(
                                "guardPostName",
                                capitalizeEachWord(el?.name)
                              );
                            }}
                          >
                            <span>Lihat Detail</span>
                            <Icon
                              icon={"arrow-back"}
                              className={Styles.arrowIcon}
                            />
                          </div>
                        )}
                      </div>
                      {width < 768 ? (
                        <PDFBarcodeDownload
                          checkpoint={el?.name}
                          id={el?._id}
                          description=""
                          headerSubText="QR Code Pos Penjagaan"
                        >
                          <div className={Styles.detailQr}>
                            <span>Download QR</span>
                            <Icon
                              icon={"scan-barcode"}
                              color={"#2C5364"}
                              size={"16"}
                            />
                          </div>
                        </PDFBarcodeDownload>
                      ) : (
                        <div
                          className={Styles.detailQr}
                          onClick={
                            isInitialLoader || isLoadingScroll
                              ? () => void {}
                              : () =>
                                  window.open(
                                    `/pdf-preview?dataQuery=barcode&id=${el?._id}&postName=${el?.name}&headerSubText=QR Code Pos Penjagaan&description=`,
                                    "_blank"
                                  )
                          }
                        >
                          <span>Detail QR</span>
                          <Icon
                            icon={"scan-barcode"}
                            color={"#2C5364"}
                            size={"16"}
                          />
                        </div>
                      )}
                    </div>
                  </Fragment>
                )
              )}
            </div>
          )}
          {isLoadingScroll && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                bottom: "1rem",
              }}
            >
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
