import { API } from "configs";

export function createAdminExternal(payload = "") {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.post(`/user/external/create`, payload, {
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`,
        },
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function deleteAdminExternal(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.delete(`/user/external/delete/${id}`, {
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`,
        },
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function updateAdminExternal(id, payload = "") {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API.patch(`/user/external/update/${id}`, payload, {
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`,
        },
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function fetchAllAdminExternal(page = 1, limit = 10) {
  return new Promise(async (resolve, reject) => {
    const client = JSON.parse(localStorage.getItem("currentClient"));
    try {
      const response = await API.get(
        `/user/external/getAll?clientCode=${client?.code}&page=${page}&limit=${limit}`,
        {
          headers: {
            Authorization: `${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
