import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Tabs from "@Molecule/Tabs";
import Button from "@Atom/Button";
import Map from "@Molecule/Map";
import Table from "@Molecule/Table";
import TabHeader from "@Atom/TabHeader";
import SearchBar from "@Atom/SearchBar";
import { fileBaseUrl } from "configs";
import ModalAddPatrolPoint from "@Molecule/_modal/AddPatrolPoint";
import { updatePatrolPoint } from "@Services/patrolPoint/updatePatrollPoint";
import Toast from "@Atom/Toast";
import PDFBarcodeDownload from "@Molecule/PDFBarcode";
import useWindowSize from "@Hooks/useWindowSize";
import { capitalizeEachWord } from "helpers/capitalizeEachWord";
import Images from "@Theme/Images";
import moment from "moment";
import { patrolHistory } from "@Services/patrolPoint/patrolHistory";
import { useDebounce } from "@Hooks/useDebounce";
import CustomDatePicker from "@Atom/CustomDatePicker";
import { EmptyHandlerForFilter } from "@Molecule/EmptyHanldes/GuardPostDetail";
import HistoryActivity from "../../GuardPos/Detail/HistoryActivity";

import ModalActivity from "../../GuardPos/Detail/ModalDetail/Activity";
import useQuery from "@Hooks/useQuery";
import LoadingSpinner from "@Molecule/LoadingSpinner";

export default function DetailPatrolPoint({ data }) {
  const isAdmin = localStorage.getItem("role") === "ADMIN";
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { height } = useWindowSize();
  const [isOpenInfoLocation, setIsOpenInfoLocation] = useState(false);
  const listTabs = ["Detail Titik Patroli", "Riwayat Patroli"];
  const { patrolPointID } = useParams();
  const [activeTab, setActivetab] = useState(
    +sessionStorage.getItem("detailActiveTabsPatrolPoint") || 0
  );
  const { width } = useWindowSize();

  const [pageHistory] = useState(1);
  const [limitHistory, setLimitHistory] = useState(10);
  const [dateHistory, setDateHistory] = useState("2024-10-17");
  const [loadingHit, setLoadingHit] = useState(true);
  const selectedDate = moment(dateHistory)
    .format("L")
    .split("/")
    .reverse()
    .join("-");
  const [searchHistory, setSearchHistory] = useState("");
  const debounceSearchHistory = useDebounce(searchHistory, 500);
  const [dataPatrolHistory, setDataPatrolHistory] = useState({
    data: [],
    totalData: 0,
  });
  const getDataPatrolHistory = useCallback(async () => {
    setLoadingHit(true);
    try {
      const res = await patrolHistory(
        pageHistory,
        limitHistory,
        patrolPointID,
        selectedDate,
        debounceSearchHistory
      );
      if (res.status) {
        console.log("DATA PATROL", res);
        setDataPatrolHistory(res);
      }
    } catch (error) {
      console.log("Error get data patrol history: ", error);
    } finally {
      setLoadingHit(false);
    }
  }, [
    selectedDate,
    debounceSearchHistory,
    limitHistory,
    pageHistory,
    patrolPointID,
  ]);

  useEffect(() => {
    getDataPatrolHistory();
  }, [getDataPatrolHistory]);

  const tableDataShift1 = useMemo(() => {
    return {
      data:
        data?.patrols
          ?.find(
            (p) =>
              p.shift === "1" ||
              p?.shift === "P" ||
              p?.shift?.toLowerCase() === "pagi"
          )
          ?.patrols?.map((el, idx) => ({
            id: idx + 1,
            name: el?.name,
            time: moment(el?.date).format("LT")?.split(".").join(":"),
            progressActivity: +el?.totalProgress,
          })) || [],

      columns: [
        {
          name: "name",
          title: <TabHeader text="" />,
          width: "40%",
          renderData: (row) => (
            <>
              <span>{row?.name}</span>
            </>
          ),
        },
        {
          name: "time",
          title: <TabHeader text="" />,
          hideOnMobile: true,
          width: "20%",
          renderData: (row) => (
            <>
              <span>{row?.time}</span>
            </>
          ),
        },
        {
          hideOnMobile: true,
          name: "progressActivity",
          title: <TabHeader text="" />,
          width: "40%",
          renderData: (row) => (
            <div className={Styles.progress}>
              <div className={Styles.loadProgress}>
                <div
                  style={{ width: (row.progressActivity * 100) / 10 + "%" }}
                ></div>
              </div>
              <span>{row.progressActivity} / 10</span>
            </div>
          ),
        },
      ],
    };
  }, [data?.patrols]);
  const tableDataShift2 = useMemo(() => {
    return {
      data:
        data?.patrols
          ?.find(
            (p) =>
              p.shift === "2" ||
              p?.shift === "S" ||
              p?.shift?.toLowerCase() === "siang"
          )
          ?.patrols?.map((el, idx) => ({
            id: idx + 1,
            name: el?.name,
            time: moment(el?.date).format("LT")?.split(".").join(":"),
            progressActivity: +el?.totalProgress,
          })) || [],

      columns: [
        {
          name: "name",
          title: <TabHeader text="" />,
          width: "40%",
          renderData: (row) => (
            <>
              <span>{row?.name}</span>
            </>
          ),
        },
        {
          name: "time",
          title: <TabHeader text="" />,
          hideOnMobile: true,
          width: "20%",
          renderData: (row) => (
            <>
              <span>{row?.time}</span>
            </>
          ),
        },
        {
          hideOnMobile: true,
          name: "progressActivity",
          title: <TabHeader text="" />,
          width: "40%",
          renderData: (row) => (
            <div className={Styles.progress}>
              <div className={Styles.loadProgress}>
                <div
                  style={{ width: (row.progressActivity * 100) / 10 + "%" }}
                ></div>
              </div>
              <span>{row.progressActivity} / 10</span>
            </div>
          ),
        },
      ],
    };
  }, [data?.patrols]);
  const tableDataShift3 = useMemo(() => {
    return {
      data:
        data?.patrols
          ?.find(
            (p) =>
              p.shift === "3" ||
              p?.shift === "M" ||
              p?.shift?.toLowerCase() === "malam"
          )
          ?.patrols?.map((el, idx) => ({
            id: idx + 1,
            name: el?.name,
            time: moment(el?.date).format("LT")?.split(".").join(":"),
            progressActivity: +el?.totalProgress,
          })) || [],

      columns: [
        {
          name: "name",
          title: <TabHeader text="" />,
          width: "40%",
          renderData: (row) => (
            <>
              <span>{row?.name}</span>
            </>
          ),
        },
        {
          name: "time",
          title: <TabHeader text="" />,
          hideOnMobile: true,
          width: "20%",
          renderData: (row) => (
            <>
              <span>{row?.time}</span>
            </>
          ),
        },
        {
          hideOnMobile: true,
          name: "progressActivity",
          title: <TabHeader text="" />,
          width: "40%",
          renderData: (row) => (
            <div className={Styles.progress}>
              <div className={Styles.loadProgress}>
                <div
                  style={{ width: (row.progressActivity * 100) / 10 + "%" }}
                ></div>
              </div>
              <span>{row.progressActivity} / 10</span>
            </div>
          ),
        },
      ],
    };
  }, [data?.patrols]);

  useEffect(() => {
    sessionStorage.setItem("detailActiveTabsPatrolPoint", activeTab);
  }, [activeTab]);

  const templateDataActivities = useMemo(() => {
    return (
      dataPatrolHistory?.data?.map((d) => ({
        id: d?.patrolID,
        name: capitalizeEachWord(d?.employee?.name),
        profilePicture: d?.employee?.profilePicture || Images.AVA_DEFAULT,
        date: d?.checkpoint?.submittedAt
          ? `${moment(d?.checkpoint?.submittedAt).format("dddd")}, ${moment(
              d?.checkpoint?.submittedAt
            ).format("LL")}`
          : "-",
        time: d?.checkpoint?.submittedAt
          ? `${moment(d?.checkpoint?.submittedAt)
              .format("LTS")
              .split(".")
              .join(":")} WIB`
          : "-",
        shift: `${
          d?.checkpoint?.shift === "P"
            ? "Pagi"
            : d?.checkpoint?.shift === "S"
            ? "Sore"
            : d?.checkpoint?.shift === "M"
            ? "Malam"
            : "-"
        }`,
        activity: capitalizeEachWord(d?.checkpoint?.title),
        statusLocation: capitalizeEachWord(d?.checkpoint?.status) || "--",
        images: d?.checkpoint?.images,
        reportDescription: d?.checkpoint?.description || "-",
      })) || []
    );
  }, [dataPatrolHistory?.data]);

  const [openModalActivity, setOpenModalActivity] = useState(false);
  const [showDataModalActivity, setShowDataModalActivity] = useState([]);
  useEffect(() => {
    const filterDataModalActivity = templateDataActivities?.filter(
      (el) => el.id === openModalActivity
    );
    setShowDataModalActivity(filterDataModalActivity);
  }, [openModalActivity, templateDataActivities]);

  const [isTransition, setTransition] = useState(false);

  useEffect(() => {
    if (openModalActivity === showDataModalActivity[0]?.id) {
      setTimeout(() => {
        setTransition(true);
      }, 100);
    } else {
      setTransition(false);
    }
  }, [openModalActivity, showDataModalActivity]);

  useEffect(() => {
    if (isOpenInfoLocation) {
      setTimeout(() => {
        setTransition(true);
      }, 100);
    }
  }, [isOpenInfoLocation]);

  const backPath = pathname.split("/").slice(0, -1).join("/");

  // update

  const [isSuccessUpdatePatrolPoint, setIsSuccessUpdatePatrolPoint] =
    useState(false);
  const [isErrorUpdatePatrolPoint, setIsErrorUpdatePatrolPoint] =
    useState(false);

  const [isLoadingHit, setIsLoadingHit] = useState(false);

  const [selectedLocation, setSelectedLocation] = useState({
    latitude: +data?.latitude,
    longitude: +data?.longitude,
  });
  const [inputPatrolPoint, setInputPatrolPoint] = useState({
    name: capitalizeEachWord(data?.description),
    image: data?.imageURI,
    latitude: data?.latitude,
    longitude: data?.longitude,
  });

  const fileRef = useRef(null);

  const handleAddPatrolPoint = async () => {
    setIsLoadingHit(true);
    setIsOpenInfoLocation(false);
    const formData = new FormData();
    formData.append("latitude", selectedLocation.latitude);
    formData.append("longitude", selectedLocation.longitude);
    formData.append("description", inputPatrolPoint.name);
    formData.append("file", inputPatrolPoint.image);

    try {
      const res = await updatePatrolPoint(data?._id, formData);
      if (res.status === 200) {
        navigate(pathname, { replace: true });
        setIsSuccessUpdatePatrolPoint(true);
      }
    } catch (error) {
      console.log("error add patrol point", error);
      setIsErrorUpdatePatrolPoint(true);
    } finally {
      setIsLoadingHit(false);
    }
  };

  //

  const listInnerRef = useRef(null);

  const onScroll = useCallback(() => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        if (limitHistory < dataPatrolHistory?.totalData) {
          setLimitHistory((prevLimit) => prevLimit + 10);
        }
      }
    }
  }, [dataPatrolHistory?.totalData, limitHistory]);

  useEffect(() => {
    if (height >= 768) {
      onScroll();
    }
  }, [height, onScroll]);

  const query = useQuery();
  const dateFilterProgressQuery =
    query.get("date") || moment(new Date()).format("YYYY-MM-DD");

  const [filterDateProgressPatrol, setFilterDateProgressPatrol] = useState(
    dateFilterProgressQuery
  );

  useEffect(() => {
    navigate(
      `${pathname}?query=_&date=${moment(filterDateProgressPatrol).format(
        "YYYY-MM-DD"
      )}`
    );
  }, [filterDateProgressPatrol, navigate, pathname]);

  return (
    <div className={`${Styles.containerDetailGuardPos}`}>
      <Toast
        isopen={isErrorUpdatePatrolPoint}
        color="red"
        onClose={() => {
          setIsErrorUpdatePatrolPoint(false);
        }}
        text={"Gagal memperbarui lokasi"}
      />

      <Toast
        isopen={isSuccessUpdatePatrolPoint}
        color="green"
        onClose={() => {
          setIsSuccessUpdatePatrolPoint(false);
        }}
        text={"Berhasil memperbarui lokasi"}
      />

      {isLoadingHit && (
        <LoadingSpinner
          text="Sedang memperbarui..."
          description="Harap tungggu data sedang perbarui"
        />
      )}

      <header className={`${Styles.header}`}>
        <div>
          <Icon
            icon={"arrow-chevron-left"}
            onClick={() => navigate(backPath)}
            className={Styles.back}
          />
          <h3>{capitalizeEachWord(data?.name)}</h3>
        </div>
        {isAdmin && (
          <Icon
            icon={"setting-2"}
            className={Styles.settings}
            onClick={() => setIsOpenInfoLocation(true)}
          />
        )}
      </header>
      <div className={Styles.wrapTabs}>
        <Tabs
          listTabs={listTabs}
          setIsActive={setActivetab}
          isActive={activeTab}
        />

        {width < 768 ? (
          <PDFBarcodeDownload
            checkpoint={capitalizeEachWord(data?.name)}
            id={data?._id}
            description={capitalizeEachWord(data?.description)}
            headerSubText="QR Code Titik Patroli"
          >
            <Button
              className={Styles.detailQr}
              text="Download QR Code"
              icon={"scan-barcode"}
              colorIcon={"#2C5364"}
              style={{ background: "transparent", color: "#2C5364" }}
            />
          </PDFBarcodeDownload>
        ) : (
          <Button
            text="Detail QR Code"
            icon={"scan-barcode"}
            colorIcon={"#2C5364"}
            style={{ background: "transparent", color: "#2C5364" }}
            onClick={() =>
              window.open(
                `/pdf-preview?dataQuery=barcode&id=${
                  data?._id
                }&postName=${capitalizeEachWord(
                  data?.name
                )}&headerSubText=QR Code Titik Patroli &description=${capitalizeEachWord(
                  data?.description
                )}`,
                "_blank"
              )
            }
          />
        )}
      </div>
      {activeTab !== 0 && (
        <div className={Styles.viewerFilter}>
          <div className={Styles.filter}>
            <SearchBar setValue={setSearchHistory} value={searchHistory} />
            {/* <Button
              text="Filter"
              icon={"setting-4"}
              colorIcon={"#2C5364"}
              style={{ background: "transparent", color: "#2C5364" }}
            /> */}
          </div>
          <CustomDatePicker
            isForModal
            value={dateHistory}
            setValue={setDateHistory}
            autoSave
          >
            <Button
              icon={"calendar"}
              colorIcon={"#2C5364"}
              style={{ background: "transparent", color: "#2C5364" }}
              text={moment(selectedDate).format("LL")}
            />
          </CustomDatePicker>
        </div>
      )}

      {isOpenInfoLocation && (
        <ModalAddPatrolPoint
          isOpen
          isFilled
          onClose={() => {
            setIsOpenInfoLocation(false);
            setTransition(false);
          }}
          isTransition={isTransition}
          fileRef={fileRef}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          inputLocation={inputPatrolPoint}
          setInputLocation={setInputPatrolPoint}
          onSubmit={() => handleAddPatrolPoint()}
        />
      )}

      {activeTab === 0 ? (
        <div className={Styles.viewerTableDetailGuard}>
          <div className={Styles.wrapPhotoAndPointLocation}>
            <div className={Styles.image}>
              <strong>Foto Lokasi Patroli</strong>
              <img src={fileBaseUrl + data?.imageURI} alt="image-location" />
            </div>
            <div className={Styles.map}>
              <strong>Lokasi Patroli</strong>
              <div className={Styles.map}>
                <Map
                  height="268px"
                  coordinate={{
                    latitude: +data?.latitude,
                    longitude: +data?.longitude,
                  }}
                  isPreview
                />
              </div>
            </div>
          </div>
          <div className={Styles.tableRow}>
            <div className={Styles.labelHeader}>
              <strong>Progres Patroli</strong>
              <CustomDatePicker
                isForModal
                value={filterDateProgressPatrol}
                setValue={setFilterDateProgressPatrol}
                autoSave
                // right="0"
              >
                <div className={Styles.selectDate}>
                  <span>
                    {moment(filterDateProgressPatrol).format("ddd, LL")}
                  </span>

                  <Icon icon={"calendar"} color={"#2C5364"} size={"14"} />
                </div>
              </CustomDatePicker>
            </div>
            <div className={Styles.viewColTable}>
              <div className={Styles.tableHead}>
                <div className={Styles.viewRow}>
                  <span>No</span>
                  <div>
                    <span>Nama</span>
                    <Icon
                      icon={"sort"}
                      color={"#3e4856"}
                      size={16}
                      className={Styles.icon}
                    />
                  </div>
                </div>
                <div className={Styles.between}>
                  <span>Jam</span>
                  <span>Progres Patroli</span>
                </div>
              </div>
              <Table
                className={Styles.table}
                data={tableDataShift1.data}
                columns={tableDataShift1.columns}
                limit={
                  tableDataShift1.data.length <= 3
                    ? 3
                    : tableDataShift1.data.length
                }
                withNumbering
                isDetailChart
                textNumber="Shift 1"
                isLoading={
                  moment(filterDateProgressPatrol).format("YYYY-MM-DD") !==
                  dateFilterProgressQuery
                }
              />
              <Table
                className={Styles.table}
                data={tableDataShift2.data}
                columns={tableDataShift2.columns}
                limit={
                  tableDataShift2.data.length <= 3
                    ? 3
                    : tableDataShift2.data.length
                }
                withNumbering
                isDetailChart
                textNumber="Shift 2"
                isLoading={
                  moment(filterDateProgressPatrol).format("YYYY-MM-DD") !==
                  dateFilterProgressQuery
                }
              />
              <Table
                className={`${Styles.table} ${Styles.tableRadius}`}
                data={tableDataShift3.data}
                columns={tableDataShift3.columns}
                limit={
                  tableDataShift3.data.length <= 3
                    ? 3
                    : tableDataShift3.data.length
                }
                withNumbering
                isDetailChart
                textNumber="Shift 3"
                isLoading={
                  moment(filterDateProgressPatrol).format("YYYY-MM-DD") !==
                  dateFilterProgressQuery
                }
              />
            </div>
          </div>
          <div className={Styles.tableTwoRow}></div>
        </div>
      ) : (
        activeTab === 1 && (
          <div
            style={{
              height: `calc(${height}px - 260px)`,
              background: "#fff",
              overflowY: "auto",
            }}
            ref={listInnerRef}
            onScroll={onScroll}
          >
            {templateDataActivities.length === 0 ? (
              <EmptyHandlerForFilter description="Tidak ada data dengan tanggal tersebut" />
            ) : (
              <HistoryActivity
                onClick={setOpenModalActivity}
                templateData={templateDataActivities}
                isLoading={loadingHit}
              />
            )}
          </div>
        )
      )}

      {activeTab === 1 &&
        openModalActivity === showDataModalActivity[0]?.id && (
          <ModalActivity
            isOpen
            isTransition={isTransition}
            setOpenModalActivity={setOpenModalActivity}
            showDataModalActivity={showDataModalActivity}
          />
        )}
    </div>
  );
}
