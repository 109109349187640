/* eslint-disable eqeqeq */
import Icon from "@Atom/Icon";
import useOutsideClick from "@Hooks/useOutsideClick";
import { get35DaysInMonth } from "helpers/get35DaysInMonth";
import { getDates } from "helpers/getDates";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Styles from "./style.module.scss";
moment.locale("en");
export default function CustomDatePicker({
  value,
  setValue = () => {},
  type = "full-date",
  padding = "8px 16px",
  right = "auto",
  left = "auto",
  disablePrevious = false,
  isRange = false,
  valueEnd,
  setValueEnd = () => {},
  // defaultDateRange = 3,
  autoSave = false,
  isForModal = false,
  isOpen = false,
  children,
  customStyle,
  className = "",
  iconSize = 16,
}) {
  const [expandOptions, setExpandOptions] = useState(false);
  const [currentValue, setCurrentValue] = useState(value || new Date());

  const [tempValue, setTempValue] = useState(value || new Date());
  const [tempValueEnd, setTempValueEnd] = useState(
    valueEnd
    // || new Date(new Date(currentValue?.getTime())?.setDate(currentValue?.getDate() + 3))
  );

  const refModalClose = useRef();
  const boxRefModalOutside = useOutsideClick(refModalClose);

  useEffect(() => {
    if (expandOptions && boxRefModalOutside) {
      setExpandOptions(false);
    }
  }, [boxRefModalOutside, expandOptions]);

  const currentMonthYear = useMemo(() => {
    return moment(new Date(currentValue))?.format("MMMM YYYY");
  }, [currentValue]);

  useEffect(() => {
    if (autoSave) {
      setValue(tempValue);
      setValueEnd(tempValueEnd);
    }
  }, [autoSave, setValue, setValueEnd, tempValue, tempValueEnd]);

  useEffect(() => {
    setCurrentValue(tempValue);
  }, [tempValue]);

  // const currentMonth = new Date(currentValue)?.getMonth()
  const currentYear = useMemo(() => {
    return new Date(currentValue)?.getFullYear();
  }, [currentValue]);
  // const valueMonth = new Date(value)?.getMonth()
  // const valueYear = new Date(value)?.getFullYear()

  const [monthYearMode, setMonthYearMode] = useState(false);

  // useEffect(() => {
  //   setCurrentValue(value)
  //   setCurrentValueEnd(valueEnd)
  //   setTempValue(value)
  //   setTempValueEnd(valueEnd)
  // }, [value, valueEnd, expandOptions])

  useEffect(() => setMonthYearMode(false), [expandOptions]);

  // eslint-disable-next-line no-unused-vars
  const types = ["full-date", "month-year", "year-only"];

  const handleReplaceSelectedMonth = () => {
    // sessionStorage.setItem("selectedMonthNumber",)
  };

  const template = useMemo(() => {
    switch (type) {
      case "year-only":
        return {
          textValue: `${moment(new Date(value))?.format("YYYY")}${
            isRange ? " - " : ""
          }${isRange ? moment(new Date(value))?.format("YYYY") : ""}`,
        };
      case "month-year":
        return {
          textValue: `${moment(new Date(value))?.format("MMMM YYYY")}`,
        };
      default:
        return {
          textValue: `${moment(new Date(value))?.format("LL")}${
            isRange ? " - " : ""
          }${isRange ? moment(new Date(valueEnd))?.format("LL") : ""}`,
        };
    }
  }, [isRange, type, value, valueEnd]);

  const handleClickPrevious = useCallback(
    (e) => {
      e.stopPropagation();
      if (!monthYearMode && type !== "month-year") {
        const now = new Date(currentValue);
        if (now.getMonth() == 0) {
          setCurrentValue(new Date(now.getFullYear() - 1, 11, 1));
        } else {
          setCurrentValue(new Date(now.getFullYear(), now.getMonth() - 1, 1));
        }
      } else {
        if (type === "month-year") {
          setTempValue(
            new Date(tempValue)?.setFullYear(
              new Date(tempValue)?.getFullYear() - 1
            )
          );
        } else {
          setCurrentValue(
            new Date(currentValue)?.setFullYear(
              new Date(currentValue)?.getFullYear() - 1
            )
          );
        }
      }
    },
    [currentValue, monthYearMode, tempValue, type]
  );

  const handleClickNext = useCallback(
    (e) => {
      e.stopPropagation();
      if (!monthYearMode && type !== "month-year") {
        const now = new Date(currentValue);
        if (now.getMonth() == 11) {
          setCurrentValue(new Date(now.getFullYear() + 1, 0, 1));
        } else {
          setCurrentValue(new Date(now.getFullYear(), now.getMonth() + 1, 1));
        }
      } else {
        if (type === "month-year") {
          setTempValue(
            new Date(tempValue)?.setFullYear(
              new Date(tempValue)?.getFullYear() + 1
            )
          );
        } else {
          setCurrentValue(
            new Date(currentValue)?.setFullYear(
              new Date(currentValue)?.getFullYear() + 1
            )
          );
        }
      }
    },
    [currentValue, monthYearMode, tempValue, type]
  );

  const ref = useRef();
  const outsideClick = useOutsideClick(ref);

  useEffect(() => {
    if (expandOptions && outsideClick) {
      setExpandOptions(false);
    }
  }, [expandOptions, outsideClick]);

  const closeItSelf = useCallback(() => {
    if (expandOptions) {
      const toClear = setTimeout(() => {
        setExpandOptions(false);
        return clearTimeout(toClear);
      }, 200);
    }
  }, [expandOptions]);

  const isDateActive = useCallback(
    (dateObj) => {
      if (!isRange) {
        return (
          moment(new Date(dateObj))?.format("YYYY-MM-DD") ===
          moment(new Date(tempValue))?.format("YYYY-MM-DD")
        );
      } else {
        const activeDates = getDates(tempValue, tempValueEnd)?.map((obj) =>
          moment(new Date(obj))?.format("YYYY-MM-DD")
        );
        const found = activeDates?.find(
          (obj) => obj === moment(new Date(dateObj))?.format("YYYY-MM-DD")
        );
        return found ? true : false;
      }
    },
    [isRange, tempValue, tempValueEnd]
  );

  const handleClickDate = useCallback(
    (d) => {
      const newDate = new Date(d);
      if (!isRange) {
        setTempValue(newDate);
      } else {
        if (
          tempValue &&
          moment(tempValue)?.format("YYYY-MM") !== moment(d)?.format("YYYY-MM")
        ) {
          setTempValue(d);
          setTempValueEnd(d);
        } else if (!tempValue && !tempValueEnd) {
          setTempValue(newDate);
        } else if (tempValue && !tempValueEnd) {
          if (new Date(d) > new Date(tempValue)) {
            setTempValueEnd(newDate);
          } else {
            setTempValue(newDate);
          }
        } else if (tempValue && tempValueEnd) {
          if (newDate > tempValueEnd) {
            setTempValueEnd(newDate);
          } else if (newDate < tempValue) {
            setTempValue(newDate);
          } else {
            if (
              newDate?.getTime() - new Date(tempValue)?.getTime() <
              tempValueEnd?.getTime() - newDate?.getTime()
            ) {
              setTempValueEnd(newDate);
            } else {
              setTempValue(newDate);
            }
          }
        }
      }
    },
    [isRange, tempValue, tempValueEnd]
  );

  const optionsCard = useMemo(() => {
    switch (type) {
      default:
        return (
          <div
            ref={isForModal ? refModalClose : ref}
            className={`${Styles.fdContainer} `}
          >
            <div className={Styles.fdHeader}>
              <button disabled={disablePrevious} onClick={handleClickPrevious}>
                <Icon
                  icon={"arrow-chevron-left"}
                  size={24}
                  color={disablePrevious ? "#CDD2DB" : ""}
                />
              </button>
              <p>
                <span>
                  {!monthYearMode && type !== "month-year"
                    ? currentMonthYear
                    : moment(
                        new Date(
                          type !== "month-year" ? currentValue : tempValue
                        )
                      )?.format("YYYY")}
                </span>
                {!monthYearMode && type !== "month-year" && (
                  <Icon
                    onClick={(e) => {
                      e.stopPropagation();
                      setMonthYearMode(!monthYearMode);
                    }}
                    icon={"arrow-down"}
                    size={10}
                    style={{
                      transform: monthYearMode ? "rotate(180deg)" : "rotate(0)",
                    }}
                  />
                )}
              </p>
              <button onClick={handleClickNext}>
                <Icon icon={"arrow-chevron-right"} size={24} />
              </button>
            </div>
            <div className={Styles.fdContent}>
              {!monthYearMode && type !== "month-year" ? (
                <div>
                  <div className={Styles.fdcDays}>
                    {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]?.map(
                      (d, dI) => (
                        <div key={dI}>{d}</div>
                      )
                    )}
                  </div>
                  <div className={Styles.fdcDates}>
                    {get35DaysInMonth(new Date(currentValue))?.map((d, dI) => (
                      <div
                        key={dI}
                        onClick={(e) => {
                          if (autoSave) {
                            closeItSelf();
                          }
                          e?.stopPropagation();
                          if (
                            moment(new Date(d))?.format("MM") ===
                            moment(new Date(currentValue))?.format("MM")
                          ) {
                            handleClickDate(d);
                          }
                        }}
                        className={`
                              ${isDateActive(new Date(d)) ? Styles.active : ""}
                                ${
                                  moment(new Date(d))?.format("MM") !==
                                  moment(new Date(currentValue))?.format("MM")
                                    ? Styles.disabled
                                    : ""
                                }
                                ${isRange ? Styles.isRange : ""}
                                ${
                                  moment(new Date(tempValue))?.format(
                                    "YYYY-MM-DD"
                                  ) ===
                                  moment(new Date(d))?.format("YYYY-MM-DD")
                                    ? Styles.rangeStarter
                                    : ""
                                }
                                ${
                                  moment(new Date(tempValueEnd))?.format(
                                    "YYYY-MM-DD"
                                  ) ===
                                  moment(new Date(d))?.format("YYYY-MM-DD")
                                    ? Styles.rangeFinisher
                                    : ""
                                }
                                `}
                      >
                        {moment(new Date(d))?.format("DD")}
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className={`${Styles.fdcDates} ${Styles.months}`}>
                  {[...Array(12).keys()]?.map((m, mI) => (
                    <div
                      key={mI}
                      className={`${
                        moment(new Date(`${currentYear}-${m + 1}-01`))?.format(
                          "MM"
                        ) === moment(new Date(tempValue))?.format("MM")
                          ? Styles.active
                          : ""
                      }`}
                      onClick={(e) => {
                        handleReplaceSelectedMonth();
                        if (autoSave) {
                          closeItSelf();
                        }
                        e?.stopPropagation();
                        if (type !== "month-year") {
                          setCurrentValue(
                            new Date(`${currentYear}-${m + 1}-01`)
                          );
                        } else {
                          setTempValue(new Date(`${currentYear}-${m + 1}-01`));
                        }
                        setMonthYearMode(false);
                      }}
                    >
                      {moment(new Date(`${currentYear}-${m + 1}-01`))
                        ?.format("MMMM")
                        ?.substring(0, 3)}
                    </div>
                  ))}
                </div>
              )}
            </div>
            {!autoSave && !isForModal && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setValue(tempValue);
                  setValueEnd(tempValueEnd);
                  setExpandOptions(false);
                }}
              >
                Simpan
              </button>
            )}
          </div>
        );
    }
  }, [
    autoSave,
    currentMonthYear,
    currentValue,
    currentYear,
    disablePrevious,
    handleClickDate,
    handleClickNext,
    handleClickPrevious,
    isDateActive,
    isForModal,
    isRange,
    monthYearMode,
    setValue,
    setValueEnd,
    tempValue,
    tempValueEnd,
    type,
    closeItSelf,
  ]);

  return (
    <>
      {isForModal ? (
        <div
          className={`${Styles.container} ${
            isForModal && Styles.modalContainer
          } `}
          onClick={() => setExpandOptions(!expandOptions)}
        >
          <div onClick={() => setExpandOptions(!expandOptions)}>{children}</div>
          {(isOpen || (expandOptions && isForModal)) && (
            <div
              className={`${Styles.card}`}
              style={customStyle ? customStyle : { left, right }}
            >
              {optionsCard}
            </div>
          )}
        </div>
      ) : (
        <div
          className={`${Styles.container} ${className}`}
          style={{ padding }}
          onClick={() => setExpandOptions(!expandOptions)}
        >
          <span>{template?.textValue}</span>
          <Icon icon={"calendar"} size={iconSize} color={"#556377"} />
          {expandOptions && (
            <div
              className={`${Styles.card}`}
              style={customStyle ? customStyle : { left, right }}
            >
              {optionsCard}
            </div>
          )}
        </div>
      )}
    </>
  );
}
