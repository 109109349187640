import React, { useEffect, useState } from "react";
import Styles from "./style.module.scss";
import Images from "@Theme/Images";
import OTPField from "@Atom/OTPField";
import login, { verifyOtp } from "@Services/auth/login";
import { useLocation, useNavigate } from "react-router-dom";
import useQuery from "@Hooks/useQuery";
import ErrorBubble from "@Atom/ErrorBubble";
import InputField from "@Atom/InputField";
import Icon from "@Atom/Icon";
import useWindowSize from "@Hooks/useWindowSize";
export default function Login() {
  const query = useQuery();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { height } = useWindowSize();
  const [otp, setOtp] = useState("");
  const [isInputEmpty, setIsInputEmpty] = useState(false);
  const [isInputEmptyOtp, setIsInputEmptyOtp] = useState(false);
  const [isNotValidOtp, setIsNotValidOtp] = useState(false);
  const [isNotValidNrk, setIsNotValidNrk] = useState(false);
  const [isNotValidTel, setIsNotValidTel] = useState(false);
  const [isExpiredOtp, setIsExpiredOtp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [triggerTimeLeft, setTriggerTimeLeft] = useState(
    !!sessionStorage.getItem("triggerTimeLeft") || false
  );
  const initialTimeLeft = 300;
  const [timeLeft, setTimeLeft] = useState(() => {
    const savedTime = sessionStorage.getItem("timeLeft");
    return savedTime ? parseInt(savedTime, 10) : initialTimeLeft;
  });
  const [isDisabledResendOtp, setIsDisabledResendOTP] = useState(true);
  useEffect(() => {
    if (timeLeft > 0 && triggerTimeLeft && !!query.get("hash")) {
      const timer = setInterval(() => {
        setTimeLeft((prev) => {
          const newTime = prev - 1;
          sessionStorage.setItem("timeLeft", newTime);
          return newTime;
        });
      }, 1000);

      return () => clearInterval(timer);
    } else if (timeLeft <= 0) {
      setIsDisabledResendOTP(false);
      sessionStorage.removeItem("timeLeft");
    }
  }, [query, timeLeft, triggerTimeLeft]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secondsLeft = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secondsLeft).padStart(
      2,
      "0"
    )}`;
  };

  const handleResendAndChangeNumber = (isChangeNumber) => {
    setIsNotValidOtp(false);
    setIsNotValidNrk(false);
    setIsNotValidTel(false);
    setIsExpiredOtp(false);
    setIsLoading(false);
    setIsInputEmpty(false);
    sessionStorage.removeItem("timeLeft");

    if (!isChangeNumber && !isDisabledResendOtp && query.get("hash")) {
      handleSendOTP();
      setIsDisabledResendOTP(true);
    } else {
      navigate("/login");
    }
  };

  const [input, setInput] = useState(
    JSON.parse(sessionStorage.getItem("formLogin")) || {
      nrk: "",
      otp: "",
    }
  );

  useEffect(() => {
    sessionStorage.setItem("formLogin", JSON.stringify(input));
  }, [input]);

  const handleChangeInput = (name, value) => {
    const isNumber = /^[+-]?\d*\.?\d*$/.test(value);
    if (name === "nrk") {
      if (isNumber) {
        setInput((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else {
      setInput((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    if (isInputEmpty && input.nrk) {
      setIsInputEmpty(false);
    }
  }, [isInputEmpty, input.nrk]);

  useEffect(() => {
    if (input.otp && isInputEmptyOtp) {
      setIsInputEmptyOtp(false);
    }
  }, [input.otp, isInputEmptyOtp]);

  useEffect(() => {
    setInput((prev) => ({
      ...prev,
      otp: otp,
    }));
  }, [otp]);

  const handleSendOTP = async () => {
    if (!input.nrk) {
      setIsNotValidNrk(false);
      setIsNotValidTel(false);
      return setIsInputEmpty(true);
    }
    setTimeLeft(300);
    sessionStorage.setItem("triggerTimeLeft", true);
    setTriggerTimeLeft(true);
    setIsLoading(true);
    try {
      const res = await login({
        IdOrPhone: input.nrk,
      });
      if (res?.status === 201) {
        navigate(
          `${pathname}?hash=${res?.data?.hash}&${
            input.nrk.startsWith("08") ? "telephone" : "nrk"
          }=${input.nrk}`
        );
        setIsLoading(false);
        setIsNotValidNrk(false);
        setIsNotValidTel(false);
        setIsInputEmpty(false);
        setIsInputEmptyOtp(false);
      }
    } catch (err) {
      setIsLoading(false);
      const message = err.response?.data?.message;

      if (message === "phone number not found") {
        setIsNotValidTel(true);
        setIsNotValidNrk(false);
      }
      if (message === "id number not found") {
        setIsNotValidNrk(true);
        setIsNotValidTel(false);
      }
      if (message === "employee id not found") {
        setIsNotValidNrk(true);
        setIsNotValidTel(false);
      }
      console.log(`Something went wrong: ${err}`);
    }
  };

  const handleVerifyOTP = async () => {
    if (input.otp === "") {
      setIsNotValidNrk(false);
      setIsNotValidTel(false);
      setIsExpiredOtp(false);
      setIsNotValidOtp(false);
      return setIsInputEmptyOtp(true);
    }
    setIsLoading(true);
    if (isExpiredOtp || isNotValidOtp) {
      setIsExpiredOtp(false);
      setIsNotValidOtp(false);
    }
    try {
      const res = await verifyOtp({
        hash: query.get("hash"),
        otp: input.otp,
      });
      if (res.status === 201) {
        localStorage.setItem("username", res?.data?.user?.name);
        localStorage.setItem("role", res?.data?.user?.role);
        localStorage.setItem(
          "profilePicture",
          res.data?.imageURL === "-" ? null : res.data?.imageURL || null
        );
        localStorage.setItem("accessToken", res?.data?.access_token);
        localStorage.setItem("adminEmployeeID", res?.data?.user?.employeeID);

        sessionStorage.clear();
        setTimeout(() => {
          setIsLoading(false);
          if (res?.data?.user?.role === "ADMIN") navigate("/client");
          else {
            localStorage.setItem(
              "currentClient",
              JSON.stringify(res?.data?.user?.client)
            );
            navigate("/");
          }
        }, 700);
      }
    } catch (error) {
      setIsLoading(false);
      const { response } = error;
      if (response.data?.message === "wrong otp code") {
        setIsNotValidOtp(true);
      }
      if (response.data?.message === "OTP expired") {
        setIsExpiredOtp(true);
      }
      console.log(`error: ${error}`);
    }
  };

  const telephoneToRender = query.get("telephone");
  const nrkToRender = query.get("nrk");

  const handleKeyDown = (e, isSendOtp = false) => {
    if (e.key === "Enter") {
      if (isSendOtp) {
        handleSendOTP();
      } else {
        handleVerifyOTP();
      }
    }
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.top} />
      <div className={Styles.content}>
        <div className={Styles.loginCard}>
          <div className={Styles.header}>
            <img src={Images.MWS_LOGO} alt="" />
            <h3>Login Akun MyWorkSpace</h3>
            {(isInputEmpty ||
              isNotValidTel ||
              isNotValidNrk ||
              isNotValidOtp ||
              isExpiredOtp ||
              isInputEmptyOtp) && (
              <div>
                <ErrorBubble
                  errorText={
                    isInputEmpty && !query.get("hash")
                      ? "NRK/Nomor telepon belum diisi"
                      : isNotValidTel
                      ? "Nomor telpon yang anda masukan salah"
                      : isNotValidNrk
                      ? "NRK yang anda masukan salah"
                      : isNotValidOtp
                      ? "OTP yang anda masukan salah"
                      : isExpiredOtp
                      ? "Waktu konfirmasi OTP anda telah habis"
                      : isInputEmptyOtp
                      ? "OTP belum diisi"
                      : ""
                  }
                  noTip
                  justifyContent="center"
                  isV2
                />
              </div>
            )}
          </div>
          <div className={Styles.main}>
            {Boolean(!query?.get("hash")) ? (
              <div className={Styles.form}>
                <InputField
                  for_login_mws={"true"}
                  title="NRK"
                  isLabel
                  textLabel="NRK/Nomor Telpon"
                  value={input.nrk}
                  setValue={(e) => handleChangeInput("nrk", e)}
                  placeholder="Masukkan NRK/nomor telpon"
                  isError={isInputEmpty || isNotValidTel || isNotValidNrk}
                  isForLoginMWS
                  onKeyDown={
                    isLoading ? () => {} : (event) => handleKeyDown(event, true)
                  }
                />
                <div className={Styles.info}>
                  <Icon
                    icon={"info-circle"}
                    size={16}
                    className={Styles.iconInfo}
                  />
                  <span>
                    Gunakan “08” di awal ketika menggunakan nomor telpon
                  </span>
                </div>
              </div>
            ) : (
              <div className={Styles.otpForm}>
                <span>Masukkan OTP</span>
                <p>
                  {!!query.get("telephone") &&
                  query.get("telephone").startsWith("08")
                    ? `Masukkan OTP yang telah kami kirim nomor telepon yang terdaftar ${telephoneToRender?.slice(
                        0,
                        2
                      )}*****${telephoneToRender?.slice(-3)}`
                    : `Masukkan OTP yang telah kami kirim NRK yang terdaftar
                      ${nrkToRender?.slice(0, 2)}*****${nrkToRender?.slice(-3)}
                      `}
                  {` atau `}{" "}
                  <span onClick={() => handleResendAndChangeNumber(true)}>
                    Ubah {query.get("telephone") ? "Nomor Telpon" : "Nomor NRK"}{" "}
                  </span>
                </p>
                <OTPField
                  length={4}
                  value={otp}
                  setValue={setOtp}
                  isError={isInputEmptyOtp || isNotValidOtp}
                  onKeyDown={(e) => handleKeyDown(e, false)}
                />
              </div>
            )}
            <div className={Styles.errorWrapper}></div>
            {query.get("hash") && (
              <div
                className={Styles.resendCode}
                style={{ paddingBottom: height <= 768 ? "1rem" : "32px" }}
              >
                <span
                  is_disabled_btn={isDisabledResendOtp.toString()}
                  className={Styles.text}
                  onClick={() =>
                    isDisabledResendOtp
                      ? {}
                      : handleResendAndChangeNumber(false)
                  }
                >
                  Kirim ulang OTP
                </span>
                <div className={Styles.timeLftLoader}>
                  <div className={Styles.loaderContainer}>
                    <div
                      is_paused_time_left={isDisabledResendOtp.toString()}
                      className={`${Styles.loader} ${
                        isDisabledResendOtp ? "" : Styles.paused
                      }`}
                      style={{ animationDuration: `${timeLeft - 300}` }}
                    />
                  </div>
                  <span className={Styles.number}>{formatTime(timeLeft)}</span>
                </div>
              </div>
            )}
            <button
              className={Styles.btnSubmit}
              disabled={isLoading}
              style={
                isLoading
                  ? {
                      background: isLoading ? "rgb(46, 49, 146, 0.8)" : "",
                      cursor: "not-allowed",
                    }
                  : {}
              }
              onClick={
                isLoading
                  ? () => {}
                  : !!query.get("hash")
                  ? () => handleVerifyOTP()
                  : () => handleSendOTP()
              }
            >
              {!!query.get("hash")
                ? `Konfirmasi OTP${isLoading ? " ..." : ""}`
                : `Login${isLoading ? " ..." : ""}`}
            </button>
          </div>
        </div>
        <div className={Styles.privacyPolicy}>
          <Icon icon={"Legal"} size={32} color={"#3E4856"} />
          <span>Kebijakan Privasi</span>
          <p>Lihat lebih detail dan pelajari kebijakan privasi kami</p>
          <button onClick={() => navigate("/privacy-policy?app=true")}>
            Lihat Kebijakan Privasi
          </button>
        </div>
      </div>
      <div className={Styles.bottom} />
      <div className={Styles.footer}>
        <span>© MWS Permata Indonesia</span>
      </div>
    </div>
  );
}
