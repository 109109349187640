import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Styles from "./style.module.scss";
import CustomEmptyHandlerPage from "@Atom/CustomEmptyHandlerPage";
import Images from "@Theme/Images";
import Button from "@Atom/Button";
import Icon from "@Atom/Icon";
import ModalAddSchemaAbsence from "@Molecule/_modal/AddSchemaAbsence";
import { getDetailSchemaAbsence } from "@Services/attendenceSchema/getDetailSchemaAbsence";
import { deleteAttendenceSchema } from "@Services/attendenceSchema/deleteAttendenceSchema";
import Toast from "@Atom/Toast";
import { useLocation, useNavigate } from "react-router-dom";
import { cretateAttendenceSchema } from "@Services/attendenceSchema/cretateAttendenceSchema";
import { updateAttendanceSchema } from "@Services/attendenceSchema/updateAttendanceSchema";
import { capitalizeEachWord } from "helpers/capitalizeEachWord";
import useQuery from "@Hooks/useQuery";
import useWindowSize from "@Hooks/useWindowSize";
import Spinner from "@Atom/Spinner";
export default function SchemaAbsenceSetting({
  data = {
    data: [],
    totalData: 0,
  },
}) {
  const isAdmin = localStorage.getItem("role") === "ADMIN";
  // TIME CONVERTER

  const convertTo12hFormat = (time) => {
    // WITH AM

    if (typeof time !== "string" || !time.includes(".")) {
      return "Invalid Time";
    }

    const [hours, minutes] = time.split(".").map(Number);

    if (hours > 24 || minutes >= 60) {
      return "Invalid Time";
    }

    const period = hours >= 12 ? "PM" : "AM";
    const displayHours = hours % 12 || 12;
    const displayMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${displayHours}:${displayMinutes} ${period}`;
  };

  const convertTo24hFormat = (time) => {
    if (
      typeof time !== "string" ||
      !time.includes(":") ||
      !(time.includes("AM") || time.includes("PM"))
    ) {
      return "Invalid Time";
    }

    const [timePart, period] = time.split(" ");
    let [hours, minutes] = timePart.split(":").map(Number);

    if (hours > 12 || minutes >= 60 || (period !== "AM" && period !== "PM")) {
      return "Invalid Time";
    }

    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }

    const displayHours = hours < 10 ? `0${hours}` : hours;
    const displayMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${displayHours}.${displayMinutes}`;
  };

  //

  const { height } = useWindowSize();
  // const page = 1;
  const [limit, setLimit] = useState(10);
  const query = useQuery();
  const limitQuery = query.get("limit");

  const CLIENT_KEY = JSON.parse(localStorage.getItem("currentClient"));
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dataTemplate = useMemo(() => {
    return data?.data?.map((s) => ({
      id: s?._id,
      name: s?.name,
      shiftings: s?.shiftings,
      description: [
        {
          icon: "timer",
          text: `${s?.shiftings?.length} Shift Kerja`,
        },
        // {
        //   icon: "timer-pause",
        //   text: "-",
        // },
        {
          icon: "report-alert",
          text: s?.intervalPatrol + "x Laporan Patroli /shift",
        },
        {
          icon: "security-man",
          text: s?.intervalGuard + "x Laporan Aktifitas /shift",
        },
      ],
    }));
  }, [data?.data]);

  const [isLoadingHit, setIsLoadingHit] = useState(false);
  const [isSuccesDeleteSchema, setIsSuccesDeleteSchema] = useState(false);
  const [isErrorDeleteSchema, setIsErrorDeleteSchema] = useState(false);

  const [isSuccesCreateSchema, setIsSuccesCreateSchema] = useState(false);
  const [isErrorCreateSchema, setIsErrorCreateSchema] = useState(false);

  const [isSuccessUpdateSchema, setIsSuccessUpdateSchema] = useState(false);
  const [isErrorUpdateSchema, setIsErrorUpdateSchema] = useState(false);

  const [isOpenAddSchema, setIsOpenAddSchema] = useState(false);
  const [isOpenDetailSchema, setIsOpenDetailSchema] = useState(false);

  //
  // const CLIENT_KEY = JSON.parse(localStorage.getItem("currentClient"));
  const fieldRadioButtons = useMemo(() => {
    return ["2 Shift Kerja", "3 Shift Kerja", "Non Shift"];
  }, []);

  const [activeShift, setActiveShift] = useState(fieldRadioButtons[0]);

  const [dataToSend, setDataToSend] = useState({
    name: "",
    amountShiftings: {
      morningShift: {
        startTime: "",
        endTime: "",
      },
      afterNoonShift: {
        startTime: "",
        endTime: "",
      },
      nightShift: {
        startTime: "",
        endTime: "",
      },
      nonShift: {
        startTime: "",
        endTime: "",
      },
    },
    timeIntervalPatrol: "",
    timeIntervalActivity: "",
  });
  const [shiftStartTimeMorning, setShiftStartTimeMorning] = useState({
    time: "",
  });
  const [shiftEndTimeMorning, setShiftEndTimeMorning] = useState({
    time: "",
  });

  const [startimeAfterNoonShift, setStartimeAfterNoonShift] = useState({
    time: "",
  });
  const [endTimeAfterNoonShift, setEndTimeAfterNoonShift] = useState({
    time: "",
  });

  const [startimeNightShift, setStartimeNightShift] = useState({
    time: "",
  });
  const [endTimeNightShift, setEndTimeNightShift] = useState({
    time: "",
  });

  const [starTimeNonShift, setStarTimeNonShift] = useState({
    time: "",
  });
  const [endTimeNonShift, setEndTimeNonShift] = useState({
    time: "",
  });

  useEffect(() => {
    setDataToSend((prev) => ({
      ...prev,
      amountShiftings: {
        morningShift: {
          startTime: shiftStartTimeMorning.time,
          endTime: shiftEndTimeMorning.time,
        },
        afterNoonShift: {
          startTime: startimeAfterNoonShift.time,
          endTime: endTimeAfterNoonShift.time,
        },
        nightShift: {
          startTime: startimeNightShift.time,
          endTime: endTimeNightShift.time,
        },
        nonShift: {
          startTime: starTimeNonShift.time,
          endTime: endTimeNonShift.time,
        },
      },
    }));
  }, [
    shiftStartTimeMorning,
    shiftEndTimeMorning,
    startimeAfterNoonShift.time,
    endTimeAfterNoonShift.time,
    startimeNightShift.time,
    endTimeNightShift.time,
    starTimeNonShift.time,
    endTimeNonShift.time,
  ]);
  //

  // get detail

  const [catchDataDetail, setCatchDataDetail] = useState({
    id: "",
    name: "",
    shift: "",
  });

  useEffect(() => {
    setActiveShift(
      catchDataDetail.shift === 4
        ? fieldRadioButtons[1]
        : catchDataDetail.shift === 3
        ? fieldRadioButtons[0]
        : fieldRadioButtons[2]
    );
  }, [catchDataDetail.shift, fieldRadioButtons]);

  const getDetailSchema = async (id) => {
    try {
      const res = await getDetailSchemaAbsence(id);
      if (res.status === 200) {
        const { data } = res?.data;
        setShiftStartTimeMorning({
          time:
            convertTo12hFormat(
              data?.shiftings?.find((el) => el.type === "MORN")?.startTime
            ) || "00.00",
        });

        setShiftEndTimeMorning({
          time:
            convertTo12hFormat(
              data?.shiftings?.find((el) => el.type === "MORN")?.endTime
            ) || "00.00",
        });

        setStartimeAfterNoonShift({
          time:
            convertTo12hFormat(
              data?.shiftings?.find((el) => el.type === "AFT")?.startTime
            ) || "00.00",
        });

        setEndTimeAfterNoonShift({
          time:
            convertTo12hFormat(
              data?.shiftings?.find((el) => el.type === "AFT")?.endTime
            ) || "00.00",
        });

        setStartimeNightShift({
          time:
            convertTo12hFormat(
              data?.shiftings?.find((el) => el.type === "NIG")?.startTime
            ) || "00.00",
        });
        setEndTimeNightShift({
          time:
            convertTo12hFormat(
              data?.shiftings?.find((el) => el.type === "NIG")?.endTime
            ) || "00.00",
        });
        setStarTimeNonShift({
          time:
            convertTo12hFormat(
              data?.shiftings?.find((el) => el.type === "NON-SHIFT")?.startTime
            ) || "00.00",
        });
        setEndTimeNonShift({
          time:
            convertTo12hFormat(
              data?.shiftings?.find((el) => el.type === "NON-SHIFT")?.endTime
            ) || "00.00",
        });

        setDataToSend({
          name: data?.name,
          timeIntervalPatrol: data?.intervalPatrol,
          timeIntervalActivity: data?.intervalGuard,
        });
      }
    } catch (error) {
      console.log("error get detail schema: ", error);
    }
  };

  // end get detail
  const handleOpenAddSchema = () => {
    setIsOpenAddSchema(true);
    setActiveShift(fieldRadioButtons[0]);
    setDataToSend({
      name: "",
      timeIntervalActivity: "",
      timeIntervalPatrol: "",
    });
    setShiftStartTimeMorning({
      time: "",
    });

    setShiftEndTimeMorning({
      time: "",
    });

    setStartimeAfterNoonShift({
      time: "",
    });

    setEndTimeAfterNoonShift({
      time: "",
    });

    setStartimeNightShift({
      time: "",
    });
    setEndTimeNightShift({
      time: "",
    });
    setStarTimeNonShift({
      time: "",
    });
    setEndTimeNonShift({
      time: "",
    });
  };

  const handleDeleteSchema = async (id) => {
    setIsLoadingHit(true);
    try {
      const res = await deleteAttendenceSchema(id);
      if (res.status === 200) {
        setIsLoadingHit(false);
        setIsOpenDetailSchema(false);
        setTimeout(() => {
          setIsSuccesDeleteSchema(true);
        }, 200);
        navigate(`${pathname}`, { replace: true });
      }
    } catch (error) {
      setIsErrorDeleteSchema(true);
      setIsLoadingHit(false);
      console.log("error delete schema: ", error);
      setIsOpenDetailSchema(false);
    }
  };

  // CREATE & UPDATE
  const handlePostSchema = async (type, schemaID) => {
    setIsLoadingHit(true);

    const form = new URLSearchParams();
    form.append("clientCode", CLIENT_KEY?.code);
    form.append("name", dataToSend.name);
    form.append("description", "-"); // ??
    form.append("intervalPatrol", dataToSend.timeIntervalPatrol);
    form.append("intervalGuard", dataToSend.timeIntervalActivity);

    const { nonShift, morningShift, afterNoonShift, nightShift } =
      dataToSend.amountShiftings;
    if (activeShift === fieldRadioButtons[2]) {
      form.append("shiftings[0][type]", "NON-SHIFT");
      form.append(
        "shiftings[0][startTime]",
        convertTo24hFormat(nonShift.startTime)
      );
      form.append(
        "shiftings[0][endTime]",
        convertTo24hFormat(nonShift.endTime)
      );
    } else if (activeShift === fieldRadioButtons[1]) {
      form.append("shiftings[0][type]", "MORN");
      form.append(
        "shiftings[0][startTime]",
        convertTo24hFormat(morningShift.startTime)
      );
      form.append(
        "shiftings[0][endTime]",
        convertTo24hFormat(morningShift.endTime)
      );
      form.append("shiftings[1][type]", "AFT");
      form.append(
        "shiftings[1][startTime]",
        convertTo24hFormat(afterNoonShift.startTime)
      );
      form.append(
        "shiftings[1][endTime]",
        convertTo24hFormat(afterNoonShift.endTime)
      );
      form.append("shiftings[2][type]", "NIG");
      form.append(
        "shiftings[2][startTime]",
        convertTo24hFormat(nightShift.startTime)
      );
      form.append(
        "shiftings[2][endTime]",
        convertTo24hFormat(nightShift.endTime)
      );
      form.append("shiftings[3][type]", "NON-SHIFT");
      form.append(
        "shiftings[3][startTime]",
        convertTo24hFormat(nonShift.startTime)
      );
      form.append(
        "shiftings[3][endTime]",
        convertTo24hFormat(nonShift.endTime)
      );
    } else {
      form.append("shiftings[0][type]", "MORN");
      form.append(
        "shiftings[0][startTime]",
        convertTo24hFormat(morningShift.startTime)
      );
      form.append(
        "shiftings[0][endTime]",
        convertTo24hFormat(morningShift.endTime)
      );
      form.append("shiftings[1][type]", "NIG");
      form.append(
        "shiftings[1][startTime]",
        convertTo24hFormat(nightShift.startTime)
      );
      form.append(
        "shiftings[1][endTime]",
        convertTo24hFormat(nightShift.endTime)
      );
      form.append("shiftings[2][type]", "NON-SHIFT");
      form.append(
        "shiftings[2][startTime]",
        convertTo24hFormat(nonShift.startTime)
      );
      form.append(
        "shiftings[2][endTime]",
        convertTo24hFormat(nonShift.endTime)
      );
    }

    if (type === "update") {
      try {
        const res = await updateAttendanceSchema(schemaID, form);
        if (res.status === 200) {
          setIsLoadingHit(false);
          setIsSuccessUpdateSchema(true);
          navigate(`${pathname}?page=1&limit=${limit}`, { replace: true });
          setIsOpenDetailSchema(false);
        }
      } catch (error) {
        setIsErrorUpdateSchema(true);
        setIsOpenDetailSchema(false);
        setIsLoadingHit(false);
        console.log("error update schema: ", error);
      }
    } else {
      try {
        const res = await cretateAttendenceSchema(form);
        if (res.status === 201) {
          setIsLoadingHit(false);
          setIsSuccesCreateSchema(true);
          navigate(`${pathname}?page=1&limit=${limit}`, { replace: true });
          setIsOpenAddSchema(false);
        }
      } catch (error) {
        setIsOpenAddSchema(false);
        setIsErrorCreateSchema(true);
        setIsLoadingHit(false);
        console.log("error create schema: ", error);
      }
    }
  };

  const listInnerRef = useRef(null);

  const onScroll = useCallback(() => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        if (limit < data.totalData) {
          setLimit((prevLimit) => prevLimit + 10);
        }
      }
    }
  }, [data.totalData, limit]);

  useEffect(() => {
    if (height >= 768) {
      onScroll();
    }
  }, [height, onScroll]);

  useEffect(() => {
    navigate(`${pathname}?page=1&limit=${limit}`);
  }, [limit, navigate, pathname]);

  const handleDisabledButton = () => {
    const { amountShiftings, name, timeIntervalActivity, timeIntervalPatrol } =
      dataToSend;

    if (
      !name ||
      !amountShiftings ||
      !timeIntervalActivity ||
      !timeIntervalPatrol
    ) {
      return true;
    }

    const validateShift = (shift) => !shift?.startTime || !shift?.endTime;

    if (activeShift === fieldRadioButtons[2]) {
      if (validateShift(amountShiftings.nonShift)) {
        return true;
      }
    } else if (activeShift === fieldRadioButtons[1]) {
      const { morningShift, afterNoonShift, nightShift } = amountShiftings;
      if (
        validateShift(amountShiftings.nonShift) ||
        validateShift(morningShift) ||
        validateShift(afterNoonShift) ||
        validateShift(nightShift)
      ) {
        return true;
      }
    } else {
      const { nonShift, morningShift, nightShift } = amountShiftings;
      if (
        validateShift(nonShift) ||
        validateShift(morningShift) ||
        validateShift(nightShift)
      ) {
        return true;
      }
    }

    return false;
  };

  return (
    <div
      className={`${Styles.containerSchemaAbsenceSetting} ${
        data?.data?.length === 0 && Styles.isEmptyData
      } `}
    >
      <div className={Styles.header}>
        <h1>Skema Absensi</h1>
        {data?.data?.length !== 0 && isAdmin && (
          <Button
            text="Tambah Skema"
            isLeftIcon
            isAddButton
            onClick={() => {
              handleOpenAddSchema();
            }}
          />
        )}
      </div>

      <Toast
        isopen={isSuccesCreateSchema}
        color="green"
        text={`Skema ${dataToSend.name} Berhasil Ditambahkan`}
        onClose={() => setIsSuccesCreateSchema(false)}
      />

      <Toast
        isopen={isErrorCreateSchema}
        color="red"
        text={`Skema ${dataToSend.name} Gagal Ditambahkan`}
        onClose={() => setIsErrorCreateSchema(false)}
      />

      <Toast
        isopen={isSuccesDeleteSchema}
        color="green"
        text={`Skema ${catchDataDetail.name} Berhasil Dihapus`}
        onClose={() => setIsSuccesDeleteSchema(false)}
      />

      <Toast
        isopen={isErrorDeleteSchema}
        color="red"
        text={`Skema ${catchDataDetail.name} Gagal Dihapus`}
        onClose={() => setIsErrorDeleteSchema(false)}
      />

      <Toast
        isopen={isSuccessUpdateSchema}
        color="green"
        text={`Skema ${catchDataDetail.name} Berhasil Diubah`}
        onClose={() => setIsSuccessUpdateSchema(false)}
      />

      <Toast
        isopen={isErrorUpdateSchema}
        color="red"
        text={`Skema ${catchDataDetail.name} Gagal Diubah`}
        onClose={() => setIsErrorUpdateSchema(false)}
      />

      <ModalAddSchemaAbsence
        isOpen={isOpenAddSchema || isOpenDetailSchema}
        onClose={() => {
          setIsOpenAddSchema(false);
          setIsOpenDetailSchema(false);
        }}
        isFilled={isOpenDetailSchema}
        fieldRadioButtons={fieldRadioButtons}
        activeShift={activeShift}
        setActiveShift={setActiveShift}
        dataToSend={dataToSend}
        setDataToSend={setDataToSend}
        shiftStartTimeMorning={shiftStartTimeMorning}
        setShiftStartTimeMorning={setShiftStartTimeMorning}
        shiftEndTimeMorning={shiftEndTimeMorning}
        setShiftEndTimeMorning={setShiftEndTimeMorning}
        endTimeAfterNoonShift={endTimeAfterNoonShift}
        endTimeNightShift={endTimeNightShift}
        endTimeNonShift={endTimeNonShift}
        setEndTimeAfterNoonShift={setEndTimeAfterNoonShift}
        setEndTimeNightShift={setEndTimeNightShift}
        setEndTimeNonShift={setEndTimeNonShift}
        setStarTimeNonShift={setStarTimeNonShift}
        setStartimeAfterNoonShift={setStartimeAfterNoonShift}
        setStartimeNightShift={setStartimeNightShift}
        starTimeNonShift={starTimeNonShift}
        startimeAfterNoonShift={startimeAfterNoonShift}
        startimeNightShift={startimeNightShift}
        isLoadingHit={isLoadingHit}
        onClickDeleteIcon={() => handleDeleteSchema(catchDataDetail.id)}
        onSubmit={() =>
          isOpenDetailSchema
            ? handlePostSchema("update", catchDataDetail.id)
            : handlePostSchema("create")
        }
        isDisabled={handleDisabledButton()}
      />

      {data?.data?.length === 0 ? (
        <CustomEmptyHandlerPage
          images={Images.SCHEMA_ABSENCE}
          title="Belum Ada Data Skema absensi"
          description="Silahkan lakukan penambahan skema absensi untuk menentukan waktu kerja personil"
          btnText="Tambah Skema Absensi"
          onClick={() => {
            handleOpenAddSchema();
          }}
          isAddButton={isAdmin}
        />
      ) : (
        <section
          className={Styles.wrapperCards}
          onScroll={onScroll}
          ref={listInnerRef}
          style={{ maxHeight: "100%", overflowY: "auto" }}
        >
          {dataTemplate.map((s, idx) => (
            <div className={Styles.card} key={idx}>
              <img src={Images.SECURITY_LOGO} alt="bg-security-logo" />
              <h3 className={Styles.headerCard}>
                <span>{capitalizeEachWord(s.name)}</span>
              </h3>
              <div className={Styles.description}>
                {s.description.map((dc, idx) => (
                  <div key={idx}>
                    <Icon
                      icon={dc.icon}
                      size={"16"}
                      className={Styles.iconDesc}
                    />
                    <span>{dc.text}</span>
                  </div>
                ))}
              </div>
              {isAdmin && (
                <div
                  className={Styles.footerCard}
                  onClick={() => {
                    getDetailSchema(s?.id);
                    setIsOpenDetailSchema(true);
                    setCatchDataDetail({
                      id: s?.id,
                      name: s?.name,
                      shift: s?.shiftings?.length,
                    });
                  }}
                >
                  <h3>Lihat Detail Skema</h3>
                  <Icon
                    icon={"arrow-back"}
                    size={"16"}
                    className={Styles.iconArrowRight}
                  />
                </div>
              )}
            </div>
          ))}
          {/* {!!dataTemplate.length && (
            <div
              className={Styles.addBtnSchema}
              onClick={() => {
                handleOpenAddSchema();
              }}
            >
              <Icon
                icon={"add-circle"}
                size={"32"}
                className={Styles.addBtnIcon}
              />
              <span>Tambah Skema</span>
            </div>
          )} */}
        </section>
      )}
      {+limitQuery !== limit && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            // background: "salmon",
          }}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
}
