import CustomEmptyHandlerPage from "@Atom/CustomEmptyHandlerPage";
import Icon from "@Atom/Icon";
import Skeleton from "@Atom/Skeleton";
import Spinner from "@Atom/Spinner";
import ModalAddLocation from "@Molecule/_modal/AddLocation";
import { getAllInfoWorkLocation } from "@Services/workLocation/getAllInfoWorkLocation";
import { fileBaseUrl } from "configs";
import moment from "moment";
import {
  Fragment,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import ModalStatusReportWithFetcher from "../Activity/ModalStatusWithFetcher";
import Styles from "./style.module.scss";
import { capitalizeEachWord } from "helpers/capitalizeEachWord";

export default function ReportIncident({
  packageCounter,
  packageLocations,
  isLoadingCounter = false,
  isLoadingLocations = false,
}) {
  const isAdmin = localStorage.getItem("role") === "ADMIN";
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [dataLocations, setDataLocations] = useState(packageLocations?.data);
  const [totalPage, setTotalPage] = useState(packageLocations?.totalPage);
  const [page, setPage] = useState(1);
  const observerRef = useRef(null); // Ref for the intersection observer target

  const [isOpenModalAddReport, setIsOpenModalAddReport] = useState();
  const navigate = useNavigate();
  const cardTemplate = useMemo(() => {
    if (isLoadingLocations) {
      return [];
    }
    return dataLocations?.map((obj) => {
      return {
        id: obj?._id,
        image: obj?.imageURI
          ? fileBaseUrl + obj?.imageURI
          : "https://placehold.co/281x200/burlywood/black",
        name: capitalizeEachWord(obj?.name),
        personil: obj?.memberCount || "0",
      };
    });
  }, [isLoadingLocations, dataLocations]);
  const [isOpenAddLocation, setIsOpenAddLocation] = useState(false);
  const [isOpenStatusAlert, setIsOpenStatusAlert] = useState(false);
  const [isOpenStatusStandBy, setIsOpenStatusStandBy] = useState(false);

  const [isTransition, setTransition] = useState(false);

  const fetchMore = useCallback(async () => {
    if (isLoadingMore || page > totalPage) return;

    setIsLoadingMore(true);
    try {
      const { data } = await getAllInfoWorkLocation(page + 1, 10);
      setDataLocations((prevData) => [...prevData, ...data?.data]);
      setTotalPage(data?.totalPage);

      setPage((prevPage) => prevPage + 1);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoadingMore(false);
    }
  }, [isLoadingMore, page, totalPage]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !isLoadingMore) {
          fetchMore();
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      }
    );

    if (observerRef.current) observer.observe(observerRef.current);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (observerRef.current) observer.unobserve(observerRef.current);
    };
  }, [fetchMore, isLoadingMore]);

  useEffect(() => {
    if (isOpenStatusAlert || isOpenStatusStandBy) {
      setTimeout(() => {
        setTransition(true);
      }, 100);
    } else {
      setTransition(false);
    }
  }, [isOpenStatusAlert, isOpenStatusStandBy]);

  return (
    <div className={Styles.containerReportActivity}>
      {!dataLocations?.length && !isLoadingLocations ? (
        <>
          <CustomEmptyHandlerPage
            description="Silahkan lakukan penambahan lokasi kerja terlebih dahulu untuk melihat laporan aktifitas"
            onClick={() => setIsOpenModalAddReport(true)}
            isAddButton={isAdmin}
          />
          {isOpenModalAddReport && (
            <ModalAddLocation
              isOpen
              isFilled
              onClose={() => setIsOpenModalAddReport(false)}
            />
          )}
        </>
      ) : (
        <div className={Styles.mainContainListReport}>
          <strong>Laporan Kejadian</strong>

          <div className={Styles.informationPersonil}>
            <div className={Styles.card}>
              <div>
                <div style={{ background: "#F9ECD3", borderColor: "#E78B00" }}>
                  <Icon icon={"Information"} color={"#E78B00"} size={"32"} />
                </div>
                <div>
                  <span>Status Siaga (Bulan Ini)</span>
                  {isLoadingCounter ? (
                    <Skeleton>
                      <h3>40</h3>
                    </Skeleton>
                  ) : (
                    <h3>{packageCounter?.siaga}</h3>
                  )}
                </div>
              </div>
              {!isLoadingCounter ? (
                <div onClick={() => setIsOpenStatusStandBy(true)}>
                  <Icon
                    icon={"maximize"}
                    color={"#2C5364"}
                    size={"20"}
                    className={Styles.icon}
                  />
                </div>
              ) : (
                <div>
                  <Skeleton w="22px" h="22px" />
                </div>
              )}
            </div>
            <div
              className={Styles.card}
              style={{ borderBottom: "3px solid #D42701" }}
            >
              <div>
                <div style={{ background: "#F2E5E2" }}>
                  <Icon
                    icon={"information-alert"}
                    color={"#D42701"}
                    size={"32"}
                  />
                </div>
                <div>
                  <span>Status Waspada (Bulan Ini)</span>
                  {isLoadingCounter ? (
                    <Skeleton>
                      <h3>40</h3>
                    </Skeleton>
                  ) : (
                    <h3>{packageCounter?.waspada}</h3>
                  )}
                </div>
              </div>
              {!isLoadingCounter ? (
                <div onClick={() => setIsOpenStatusAlert(true)}>
                  <Icon
                    icon={"maximize"}
                    color={"#2C5364"}
                    size={"20"}
                    className={Styles.icon}
                  />
                </div>
              ) : (
                <div>
                  <Skeleton w="22px" h="22px" />
                </div>
              )}
            </div>
          </div>

          {isOpenStatusStandBy && (
            <ModalStatusReportWithFetcher
              isTransition={isTransition}
              onClose={() => {
                setIsOpenStatusStandBy(false);
              }}
              isStandBy={isOpenStatusStandBy}
              textHead={"Daftar Laporan Dengan Status Siaga"}
              status="SIAGA"
              type="event"
            />
          )}

          {isOpenStatusAlert && (
            <ModalStatusReportWithFetcher
              isTransition={isTransition}
              onClose={() => {
                setIsOpenStatusAlert(false);
              }}
              textHead={"Daftar Laporan Dengan Status Waspada"}
              status="WASPADA"
              type="event"
            />
          )}

          <div className={Styles.cardLocation}>
            {!isLoadingLocations
              ? cardTemplate.map((el, idx) => (
                  <Fragment key={idx}>
                    <div className={Styles.card}>
                      <img alt="image-location" src={el?.image} />
                      <div className={Styles.description}>
                        <div className={Styles.personil}>
                          <strong>{el?.name}</strong>
                          <div>
                            <Icon
                              icon={"people"}
                              className={Styles.peopleIcon}
                            />
                            <span>{el?.personil} Personil</span>
                          </div>
                        </div>
                        <div
                          className={Styles.btnCard}
                          onClick={() =>
                            navigate(
                              `detail/${el?.id}?locationName=${
                                el?.name
                              }&date=${moment(new Date())?.format(
                                "YYYY-MM-DD"
                              )}&shift=P`
                            )
                          }
                        >
                          <span>Lihat Laporan</span>
                          <Icon
                            icon={"arrow-back"}
                            className={Styles.arrowIcon}
                          />
                        </div>
                      </div>
                    </div>
                    {/* {cardTemplate.length === idx + 1 && (
                  <div
                    className={`${Styles.card} ${Styles.addBtnCard}`}
                    onClick={() => setIsOpenAddLocation(true)}
                  >
                    <Icon icon={"add-circle"} color={"#2C5364"} size={"32"} />
                    <span style={{ fontSize: "14px" }}>Tambah Lokasi</span>
                  </div>
                )} */}
                  </Fragment>
                ))
              : [...Array(4)].map((el, idx) => (
                  <Fragment key={idx}>
                    <div className={Styles.card}>
                      <Skeleton h="190px">
                        <img alt="image-location" src={el?.image} />
                      </Skeleton>
                      <div className={Styles.description}>
                        <div className={Styles.personil}>
                          <Skeleton>
                            <strong>{el?.name}</strong>
                          </Skeleton>
                          <Skeleton>
                            <div>
                              <Icon
                                icon={"people"}
                                className={Styles.peopleIcon}
                              />
                              <span>{el?.personil} Personil</span>
                            </div>
                          </Skeleton>
                        </div>
                        <Skeleton>
                          <div
                            className={Styles.btnCard}
                            onClick={() =>
                              navigate(
                                `detail/${el?.id}?locationName=${el?.name}`
                              )
                            }
                          >
                            <span>Lihat Laporan</span>
                            <Icon
                              icon={"arrow-back"}
                              className={Styles.arrowIcon}
                            />
                          </div>
                        </Skeleton>
                      </div>
                    </div>
                    {/* {cardTemplate.length === idx + 1 && (
                <div
                  className={`${Styles.card} ${Styles.addBtnCard}`}
                  onClick={() => setIsOpenAddLocation(true)}
                >
                  <Icon icon={"add-circle"} color={"#2C5364"} size={"32"} />
                  <span style={{ fontSize: "14px" }}>Tambah Lokasi</span>
                </div>
              )} */}
                  </Fragment>
                ))}
          </div>
          {page < totalPage && !isLoadingLocations && (
            <div
              ref={observerRef}
              style={{ height: "20px", backgroundColor: "transparent" }}
            />
          )}
          {isLoadingMore && (
            <div className={Styles.isLoadingMore}>
              <Spinner />
            </div>
          )}
          {isOpenAddLocation && (
            <Suspense fallback={<p>Loading component...</p>}>
              <ModalAddLocation
                isOpen={isOpenAddLocation}
                onClose={() => setIsOpenAddLocation(false)}
              />
            </Suspense>
          )}
        </div>
      )}
    </div>
  );
}
