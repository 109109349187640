import React, { useCallback, useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import Button from "@Atom/Button";
import Table from "@Molecule/Table";
import { EmptyHandlerCoordinator } from ".";
import Toast from "@Atom/Toast";
import Modal from "@Atom/Modal";
import InputField from "@Atom/InputField";
import Icon from "@Atom/Icon";
import TabHeader from "@Atom/TabHeader";
import { capitalizeEachWord } from "helpers/capitalizeEachWord";
import {
  createAdminExternal,
  deleteAdminExternal,
  fetchAllAdminExternal,
  updateAdminExternal,
} from "@Services/clients/adminExternal";
export default function TableAdminExternal() {
  const clientActive = JSON.parse(localStorage?.getItem("currentClient"));
  const [isSuccessDeleteCoorExt, setIsSuccessDeleteCoorExt] = useState(false);
  const [isErrorDeleteCoorExt, setIsErrorDeleteCoorExt] = useState(false);
  const [deleteCoorName, setDeleteCoorName] = useState(null);
  const [isSuccessCreateCoor, setIsSuccessCreateCoor] = useState(false);
  const [isErrorCreateCoor, setIsErrorCreateCoor] = useState(false);
  const [isLoadingHitCreateCoor, setIsLoadingHitCreateCoor] = useState(false);
  const [limitCoordnator, setLimitCoordnator] = useState(10);
  const [pageCoordinator, setPageCoordinator] = useState(1);
  const [isSuccessUpdateCoor, setIsSuccessUpdateCoor] = useState(false);
  const [isErrorUpdateCoor, setIsErrorUpdateCoor] = useState(false);
  const [isLoadingHitFetchCoor, setIsLoadingHitFetchCoor] = useState(false);

  const [dataAdminExternal, setDataAdminExternal] = useState({
    totalPage: 1,
    totalData: 0,
    data: [],
  });
  const [isOpenModalAddCoordinator, setIsOpenModalAddCoordinator] =
    useState(false);
  const isAdmin = localStorage.getItem("role") === "ADMIN";
  const [valueAddCoordinator, setValueAddCoordinator] = useState({
    name: "",
    position: "",
    phoneNumber: "",
  });

  const handleChangeAddCoordinator = (value, name) => {
    if (name === "phoneNumber") {
      setValueAddCoordinator((prev) => ({
        ...prev,
        [name]: value?.replace(/[^\d+]/g, ""),
      }));
    } else {
      setValueAddCoordinator((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  const handleClearDataEditCoor = () => {
    if (isOpenModalAddCoordinator?.dataEdit) {
      setValueAddCoordinator((prev) => ({
        ...prev,
        name: "",
        phoneNumber: "",
        position: "",
      }));
    }
  };

  // FETCH
  const getAllAdminExternal = useCallback(
    async (refetchLoad = true) => {
      setIsLoadingHitFetchCoor(refetchLoad);

      try {
        const res = await fetchAllAdminExternal(
          pageCoordinator,
          limitCoordnator
        );
        if (res.status === 200) {
          setDataAdminExternal(res?.data);
        }
      } catch (error) {
        console.log("Error get all coordinator: ", error);
      } finally {
        setIsLoadingHitFetchCoor(false);
      }
    },
    [limitCoordnator, pageCoordinator]
  );

  // DELETE
  const handleDeleteCoorExt = useCallback(
    async (id) => {
      setIsSuccessDeleteCoorExt(false);
      setIsErrorDeleteCoorExt(false);

      try {
        const res = await deleteAdminExternal(id);
        if (res.status === 200) {
          setIsSuccessDeleteCoorExt(true);
          setPageCoordinator(1);
          getAllAdminExternal(true);
        }
      } catch (error) {
        setIsErrorDeleteCoorExt(true);
        console.log("Error delete coordinator external: ", error);
      }
    },
    [getAllAdminExternal]
  );

  useEffect(() => {
    getAllAdminExternal();
  }, [getAllAdminExternal]);

  // UPDATE
  const handleUpdateCoordinator = async () => {
    setIsLoadingHitCreateCoor(true);
    setIsSuccessUpdateCoor(false);
    setIsErrorUpdateCoor(false);
    try {
      const res = await updateAdminExternal(
        isOpenModalAddCoordinator?.dataEdit?.id,
        {
          name: valueAddCoordinator?.name,
          phoneNumber: valueAddCoordinator?.phoneNumber?.split("+").join(""),
          position: valueAddCoordinator?.position,
        }
      );
      if (res.status === 200) {
        setIsOpenModalAddCoordinator(false);
        setIsSuccessUpdateCoor(true);
        getAllAdminExternal(true);
        handleClearDataEditCoor();
        setValueAddCoordinator({
          phoneNumber: "",
          name: "",
          position: "",
        });
      }
    } catch (error) {
      setIsErrorUpdateCoor(true);
      console.log("Error update coordinator: ", error);
    } finally {
      setIsLoadingHitCreateCoor(false);
    }
  };

  // CREATE
  const handleAddAdminExternal = async () => {
    setIsLoadingHitCreateCoor(true);
    setIsErrorCreateCoor(false);
    setIsSuccessCreateCoor(false);
    try {
      const { name, phoneNumber, position } = valueAddCoordinator;
      const toSendPhoneNumber = phoneNumber.startsWith("+")
        ? phoneNumber.slice(1)
        : phoneNumber
        ? phoneNumber.slice(0)
        : phoneNumber;

      const dataToSend = new URLSearchParams();
      dataToSend.append("name", name);
      dataToSend.append("phoneNumber", toSendPhoneNumber);
      dataToSend.append("clientCode", clientActive.code);
      if (position) {
        dataToSend.append("position", position);
      }

      const res = await createAdminExternal(dataToSend);
      if (res.status === 201 || res.status === 200) {
        setIsSuccessCreateCoor(true);
        getAllAdminExternal(true);
        setIsOpenModalAddCoordinator(false);
        setValueAddCoordinator({
          phoneNumber: "",
          name: "",
          position: "",
        });
      }
    } catch (error) {
      setIsErrorCreateCoor(true);
      console.log("Error create coordinator: ", error);
    } finally {
      setIsLoadingHitCreateCoor(false);
    }
  };

  useEffect(() => {
    if (
      typeof isOpenModalAddCoordinator?.dataEdit === "object" &&
      isOpenModalAddCoordinator?.dataEdit !== null
    ) {
      const { dataEdit } = isOpenModalAddCoordinator;
      setValueAddCoordinator((prev) => ({
        ...prev,
        name: dataEdit?.name,
        phoneNumber: dataEdit?.phoneNumber,
        position: dataEdit?.position,
      }));
    }
  }, [clientActive.code, isOpenModalAddCoordinator]);

  const dataTableAdminExternal = useMemo(() => {
    return {
      data: dataAdminExternal?.data?.map((c, idx) => ({
        id: c?._id || idx,
        name: capitalizeEachWord(c?.name),
        position: c?.position ? c?.position : "-",
        phoneNumber: `+${c?.phoneNumber}`,
      })),
      columns: [
        {
          name: "name",
          title: <TabHeader text="Nama" />,
          width: "40%",
          renderData: (row) => (
            <div>
              <span style={{ color: "#3E4856" }}>{row.name}</span>
            </div>
          ),
        },
        {
          name: "",
          width: "30%",
          title: <TabHeader text="Jabatan" />,
          renderData: (row) => (
            <div>
              <span style={{ color: "#3E4856" }}>{row.position}</span>
            </div>
          ),
        },
        {
          name: "",
          width: "30%",
          title: <TabHeader text="No Whatsapp" />,
          renderData: (row) => (
            <div style={{ display: "flex", gap: "2px", alignItems: "center" }}>
              <span style={{ color: "#A9B3C1" }}>
                {row.phoneNumber?.slice(0, 3)}
              </span>
              <span style={{ color: "#3E4856" }}>
                {row.phoneNumber?.slice(3)}
              </span>
            </div>
          ),
        },
        {
          name: "",
          renderData: (row) => (
            <div
              style={{
                display: isAdmin ? "flex" : "none",
                gap: "12px",
                alignItems: "center",
              }}
            >
              <Icon
                icon={"trash"}
                style={{ cursor: "pointer" }}
                color={"red"}
                size={"16"}
                onClick={() => {
                  handleDeleteCoorExt(row?.id);
                  setDeleteCoorName(row?.name);
                }}
              />
              <Icon
                onClick={() => setIsOpenModalAddCoordinator({ dataEdit: row })}
                icon={"edit-2"}
                style={{ cursor: "pointer" }}
                color={"#3e4856"}
                size={"18"}
              />
            </div>
          ),
        },
      ],
    };
  }, [dataAdminExternal?.data, handleDeleteCoorExt, isAdmin]);

  return (
    <div className={Styles.coordinator}>
      <div className={Styles.between}>
        <h3>Admin Eksternal</h3>
        {isAdmin && (
          <Button
            text="Tambah Admin Eksternal"
            isAddButton
            style={{
              background: "transparent",
              color: "#2C5364",
              border: "none",
              paddingInline: "0",
            }}
            colorIcon={"#2C5364"}
            onClick={() => setIsOpenModalAddCoordinator(true)}
          />
        )}
      </div>
      <div className={`${Styles.card} ${Styles.coordinatorCard}`}>
        <div className={Styles.wrapperTableToScroll}>
          <Table
            data={dataTableAdminExternal.data}
            columns={dataTableAdminExternal.columns}
            totalData={dataAdminExternal.totalData}
            totalPage={Math.ceil(dataAdminExternal.totalData / limitCoordnator)}
            withPagination={dataAdminExternal?.totalData > 10}
            limit={limitCoordnator}
            setLimit={setLimitCoordnator}
            page={pageCoordinator}
            setPage={setPageCoordinator}
            className={Styles.tableRadius}
            isLoading={isLoadingHitFetchCoor}
            customEmptyHandler={() => (
              <EmptyHandlerCoordinator
                onClick={() => setIsOpenModalAddCoordinator(true)}
                isAddButton={isAdmin}
                textBtn="Admin External"
              />
            )}
            isCustomEmptyHandler
          />
        </div>
        <Toast
          isopen={isSuccessCreateCoor || isErrorCreateCoor}
          color={isSuccessCreateCoor ? "green" : "red"}
          text={
            isSuccessCreateCoor
              ? "Admin eksternal berhasil ditambahkan"
              : "Admin eksternal gagal ditambahkan"
          }
          onClose={() => {
            setIsSuccessCreateCoor(false);
            setIsErrorCreateCoor(false);
          }}
          timeOut={3000}
        />

        <Toast
          isopen={isSuccessUpdateCoor || isErrorUpdateCoor}
          color={isSuccessUpdateCoor ? "green" : "red"}
          text={
            isSuccessUpdateCoor
              ? "Admin eksternal berhasil diperbarui"
              : "Admin eksternal gagal diperbarui"
          }
          onClose={() => {
            setIsSuccessUpdateCoor(false);
            setIsErrorUpdateCoor(false);
          }}
          timeOut={3000}
        />

        <Toast
          isopen={isSuccessDeleteCoorExt || isErrorDeleteCoorExt}
          color={isSuccessDeleteCoorExt ? "green" : "red"}
          text={
            isSuccessDeleteCoorExt
              ? `${deleteCoorName} dari Admin eksternal berhasil hapus`
              : `${deleteCoorName} dari Admin eksternal gagal hapus`
          }
          onClose={() => {
            setIsSuccessDeleteCoorExt(false);
            setIsErrorDeleteCoorExt(false);
          }}
          timeOut={5000}
        />

        <Modal
          isOpen={
            isOpenModalAddCoordinator === true ||
            (typeof isOpenModalAddCoordinator?.dataEdit === "object" &&
              isOpenModalAddCoordinator?.dataEdit !== null)
          }
        >
          <div className={Styles.modalAddCoordinator}>
            <div className={Styles.header}>
              <Icon icon={"card-add"} size={"24"} color={"#2C5364"} />
              <strong>
                {isOpenModalAddCoordinator?.dataEdit ? "Edit" : "Tambah"} Admin
                Eksternal
              </strong>
            </div>
            <InputField
              isLabel
              textLabel="Nama"
              placeholder="Masukkan nama"
              setValue={(e) => handleChangeAddCoordinator(e, "name")}
              value={valueAddCoordinator?.name}
              isRequired
            />
            <InputField
              isLabel
              textLabel="Jabatan"
              placeholder="Masukkan nama jabatan"
              setValue={(e) => handleChangeAddCoordinator(e, "position")}
              value={valueAddCoordinator?.position}
            />
            <InputField
              isLabel
              textLabel="Nomor Whatsapp"
              placeholder="+62"
              setValue={(e) => handleChangeAddCoordinator(e, "phoneNumber")}
              value={
                !valueAddCoordinator?.phoneNumber?.startsWith("+")
                  ? ""
                  : valueAddCoordinator.phoneNumber
              }
              isRequired
              maxLength={15}
            />
            <div className={Styles.buttonAction}>
              <Button
                text="kembali"
                style={{
                  background: "transparent",
                  color: "#2C5364",
                  padding: "8px 12px",
                }}
                onClick={() => {
                  handleClearDataEditCoor();
                  setIsOpenModalAddCoordinator(false);
                }}
              />
              <Button
                text="Simpan"
                isLoadingIndicator={isLoadingHitCreateCoor}
                onClick={
                  isOpenModalAddCoordinator?.dataEdit
                    ? () => handleUpdateCoordinator()
                    : () => handleAddAdminExternal()
                }
                isDisabled={
                  !valueAddCoordinator.name ||
                  !valueAddCoordinator.phoneNumber?.startsWith("+")
                }
              />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
